import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts';
import {
  PageLoader,
  showToastAlert,
  parseURLParams,
  OTPModal
} from '../Services/Utility';


const Login = (props) => {
  const { firebase, setUser } = useContext(FirebaseContext);
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(true);
  const [change2faModals, set2faModals] = useState([]);

  useEffect(() => {
    props.toggleSideMenu(false);
    document.body.classList.forEach(x => document.body.classList.remove(x));
    document.body.classList.add('login-page');
  }, []);

  useEffect(() => {
    let urlString = window.location.href;
    let urlParams = parseURLParams(urlString);
    let currentUrl = urlString.split('?')[0];
    if (urlParams) {
      if (urlParams.status && urlParams.status[0] === 'success') {
        try {
          const token = urlParams.token[0];
          const newSignup = urlParams.signup[0];
          firebase.auth.signInWithCustomToken(token).then(async userInfo => {
            if (newSignup == 'true') {
              // const lm_data = window.lm.getReferralData();
              // firebase.db.ref(`users/${firebase.auth.currentUser.uid}/`).update({ f_data: lm_data });
              firebase.analytic.logEvent('sign_up', { method: 'Discord' });
            }
            let from, hash;
            if (urlParams.state && urlParams.state[0]) {
              const json = JSON.parse(urlParams.state[0]);
              from = json.from;
              hash = json.hash;
            }
            const state = {};
            if (from) state['from'] = from;
            if (hash) {
              if (hash.startsWith('#')) {
                state['hash'] = hash;
              }
              else {
                state['hash'] = '#' + hash;
              }
            }
            props.location.state = state;
            window.history.replaceState(state, document.title, currentUrl);
          })
            .catch(error => {
              if (error.code == 'auth/multi-factor-auth-required') {
                // The user is a multi-factor user. Second factor challenge is required.
                let resolver = error.resolver;
                change2faState(true, resolver);
                // ...
              } else {
                console.log(error);
                showToastAlert('Error', error.message);
              }
            })
        } catch (error) {
          showToastAlert('Error', 'Error while verifying Discord');
        }
      } else if (urlParams.message) {
        window.history.replaceState({}, document.title, currentUrl);
        showToastAlert("error", urlParams.message[0]);
      }
    }
  }, []);

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        if (user) {
          if (props && props.location && props.location.state && props.location.state.from && props.location.state.from != '') {
            const pushUrl = '/' + props.location.state.from + (props.location.state.hash && props.location.state.hash || '');
            history.push(pushUrl);
          }
          else {
            history.push('/dashboard', {});
          }
        } else {
          setLoading(false);
        }
      });
    })();
  }, [history, firebase.auth]);

  const change2faState = (enabled, resolver) => {
    // Ask user which second factor to use.
    set2faModals([
      <OTPModal
        showModal={true}
        key={Math.random()}
        enabled={enabled}
        firebase={firebase}
        resolver={resolver}
      />
    ]);
  }

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  }

  const googleLogin = (event) => {
    props.toggleSideMenu(false);
    firebase.doGoogleLogin().then(resUser => {
      if (resUser.additionalUserInfo.isNewUser) {
        // const lm_data = window.lm.getReferralData();
        // firebase.db.ref(`users/${firebase.auth.currentUser.uid}/`).update({ f_data: lm_data });
        firebase.analytic.logEvent('sign_up', { method: 'Google' });
        showToastAlert('Success', "Account created successfully.");
      }
      else {
        showToastAlert('Success', "Account loggedin successfully.");
      }
    }).catch(error => {
      if (error.code == 'auth/multi-factor-auth-required') {
        // The user is a multi-factor user. Second factor challenge is required.
        let resolver = error.resolver;
        change2faState(true, resolver);
        // ...
      } else {
        console.log(error);
        showToastAlert('Error', error.message);
      }
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    props.toggleSideMenu(false);
    firebase.doSignInWithEmailAndPassword(values.email, values.password)
      .then(resUser => {
        setUser(resUser);
      })
      .catch(error => {
        if (error.code == 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.
          let resolver = error.resolver;
          change2faState(true, resolver);
          // ...
        } else {
          console.log(error);
          showToastAlert('Error', error.message);
        }
      })
  }

  const loginDiscord = async () => {

    let url = `${window.location.origin}${window.location.pathname}`;
    let state = { 'redirect_url': url };
    if (props && props.location && props.location.state && props.location.state.from && props.location.state.from != '') {
      state['from'] = props.location.state.from;
    }
    if (props && props.location && props.location.state && props.location.state.hash && props.location.state.hash != '') {
      if (props.location.state.hash.startsWith('#')) {
        state['hash'] = props.location.state.hash.slice(1);
      }
      else {
        state['hash'] = props.location.state.hash;
      }

    }
    state = JSON.stringify(state);
    let discordUrl = `https://discordapp.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_URL}/discord/callback&response_type=code&scope=identify%20email%20guilds%20guilds.join&state=${state}`;
    window.location.href = discordUrl;
  }

  return (
    <>
      {loading && <PageLoader />}
      <section>
        {
          change2faModals.map(change2faModal => (
            change2faModal
          ))
        }
        <div className="form-part">
          <div className="form-inner">
            <h2 className="mb-3">Welcome to Stella Proxies!</h2>

            <p>
              Please sign into your account, Discover inspiring connection
              opportunities and take the first step towards your dreams..
            </p>

            <div className='d-flex social mt-5'>
              <div className="google-login">
                <a href="#" onClick={googleLogin}>
                  <img src={require("assets/images/google.png")} alt="" className="pe-2" /> Log in with
                  Google
                </a>
              </div>
              <div className="discord-login">
                <a href="#" onClick={loginDiscord}>
                  <img src={require("assets/images/discord1.png")} alt="" className="pe-2" /> Log in with
                  Discord
                </a>
              </div>
            </div>

            <div className="hr-line">
              <img src={require("assets/images/hr-line.svg")} alt="" className="w-100" />
            </div>

            <div className="form-field">
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  aria-describedby="emailHelp"
                  placeholder="Username/ Email"
                  onChange={handleChange}

                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-5 form-check">
                <input
                  type="checkbox"
                  className="form-check-input w-custom-2"
                  id="exampleCheck1"
                />

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <label className="form-check-label" htmlFor="exampleCheck1" style={{ marginTop: '6px' }}>Save Login info</label>
                  <a href="/forgot-password" className="forgot-pass">Forgot Password?</a>
                </div>
              </div>

              <div className="form-cta-btn">
                <a href="#" className="mb-3" onClick={handleSubmit}>Log In</a>
                <a href="/register" className="mb-2">Create New Account</a>
              </div>

              <div className="mt-4">
                <a href="/tos" className="mb-5" style={{ float: 'left', color: 'var(--main-blue)', textDecoration: 'none', fontSize: '1.15rem', fontWeight: '600' }} target='__blank'>Terms of Service</a>
                <a href="/privacy" className="mb-5" style={{ float: 'right', color: 'var(--main-blue)', textDecoration: 'none', fontSize: '1.15rem', fontWeight: '600' }} target='__blank'>Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login;