import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts/Firebase';
import Navbar from "components/Layout/Navbar";
import $ from 'jquery';

import {
  ChangePassword,
  OTPModal,
  showToastAlert,
  fetchDiscordUser
} from 'Services/Utility';


const Settings = (props) => {
  const { user, firebase } = useContext(FirebaseContext);
  const history = useHistory();
  const [discordData, setDiscordData] = useState({});
  const [verificationStatus, setVerificationStatus] = useState(user &&  user.multiFactor.enrolledFactors && user.multiFactor.enrolledFactors.length > 0 ? true: false);

  const [changePasswordModals, setChangePasswordModals] = useState([]);
  const [change2faModals, set2faModals] = useState([]);
  const [values, setValues] = useState({ discordUrl: '', twitterUrl: '', instaUrl: '', fbUrl: '', flipdId: '', stellarId: '', lumenId: '' });

  const generateStripeLink = async () => {
    let idToken = await firebase.auth.currentUser.getIdToken(true);
    let link = await manageSubscription(idToken);
    if (link && link.status == "success" && link.session && link.session.url) {
      window.open(link.session.url, "_blank");
    }
  }

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  }

  const change2faState = async (event) => {
    console.log(user);
    if(verificationStatus) {
      try {
        let options = user.multiFactor.enrolledFactors;
        await user.multiFactor.unenroll(options[0]);
        showToastAlert('Success', 'Two factor authentication disabled.');
        setVerificationStatus(false);
      } catch (error) {
        console.log(error);
        showToastAlert('Error', error.message);
      }
     
    }else{
      set2faModals([
        <OTPModal
          showModal={true}
          key={Math.random()}
          enabled={false}
          firebase={firebase}
          user={user}
        />
      ]);
    }
    
  }

  const saveProfile = async () => {

    const username = $('#username').val().trim();
    firebase.db.ref(`users/${firebase.auth.currentUser.uid}`).update(values);

    firebase.auth.currentUser.updateProfile({
      displayName: username
    }).then(() => {
      showToastAlert('Success', 'Profile Saved');
    }).catch((error) => {
      showToastAlert('Error', error.message);
    });
  }

  const openChangePwdModal = async () => {
    try {

      setChangePasswordModals([]);

      if (!user) {
        showToastAlert('Error', 'Log in required to change password');
        return;
      }
      setChangePasswordModals([
        <ChangePassword
          showModal={true}
          key={Math.random()}
          firebase={firebase}
        />
      ]);
    } catch (error) {
      showToastAlert('Error', error.message);
    }
  }

  const manageSubscription = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/stripe/billingDetails`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Datacenter proxies!';
          console.log('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  useEffect(() => {
    document.body.classList.add('dash-bg');
  }, []);

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (!user) {
            history.push('/');
            return;
          }
          if(user &&  user.multiFactor.enrolledFactors && user.multiFactor.enrolledFactors.length){
            setVerificationStatus(true)
          }else{
            setVerificationStatus(false)
          }
          console.log(user);
          let idToken = await firebase.auth.currentUser.getIdToken(true);
          let userData = (await firebase.db.ref(`users/${user.uid}`).once('value')).val();
          if (userData) {
            setValues({ discordUrl: userData.discordUrl || '', instaUrl: userData.instaUrl || '', twitterUrl: userData.twitterUrl || '', fbUrl: userData.fbUrl || '', flipdId: userData.flipdId || '', stellarId: userData.stellarId || '', lumenId: userData.lumenId || '' });
            if (userData.discordId) {
              try {
                let discord_user = await fetchDiscordUser(userData.discordId, idToken);
                setDiscordData(discord_user);
              } catch (error) {
                console.log(error);
              }
            }
          }

        } catch (error) {
          console.log(error);
          showToastAlert('Error', error.message);
        }
      });
    })();
  }, [history, firebase.auth]);

  return (
    <div id="page-content-wrapper" className="extra-p">

      <Navbar toggleSideMenu={props.toggleSideMenu} />

      {user && user.providerData[0] && user.providerData[0].providerId && user.providerData[0].providerId === 'password' &&
        changePasswordModals.map(changePwdModal => (
          changePwdModal
        ))}
      {
        change2faModals.map(change2faModal => (
          change2faModal
        ))
      }
      <div className="dash-inner">
        <div className="row">
          <div className="col-lg-6">

            <div className="dash-head">
              <img src={require("assets/images/head-sub.svg")} alt="" /> <h4 className="mb-0">Subscriptions</h4>
            </div>

            <div className="form-cta-btn d-inline-block mt-4">
              <a href="#" onClick={generateStripeLink} className="mb-3 px-5">Manage Subscriptions</a>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="dash-head">
              <img src={require("assets/images/head-profile.svg")} alt="" /> <h4 className="mb-0">Profile Settings</h4>
            </div>

            <div className="profile-user">

              <div className="form-field">
                <div className="form-group row mb-3">
                  <label htmlFor="username" className="col-sm-2 col-form-label">Username</label>
                  <div className="col-sm-10">
                    <div className="form-control">
                      <input type="text" className="w-100" style={{ padding: 0, border: "none", backgroundColor: "transparent" }} id="username" defaultValue={user && user.displayName ? user.displayName : ''} />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Discord ID</label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" value={discordData && discordData.id ? discordData.id : '-'} readOnly />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Email</label>
                  <div className="col-sm-10">
                    <input type="email" className="form-control" value={user ? user.email : '-'} readOnly />
                  </div>
                </div>
                {
                  user && user.providerData[0] && user.providerData[0].providerId && user.providerData[0].providerId === 'password' &&
                  <div className="form-group row mb-3">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label"></label>
                    <div className="col-sm-10">
                      <div className="mt-4 change-pass">
                        <a type="button" href="#" onClick={openChangePwdModal}>Change Password</a>
                      </div>
                    </div>
                  </div>
                }
                <div className="form-group row mb-0">
                  <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Two Factor Auth</label>
                  <div className="col-sm-10 col-form-label">
                    
                    <div className="form-cta-btn d-inline-block mt-4">
                      <a href="#" onClick={change2faState} className="mb-3 px-5 text-light">{verificationStatus ? "Disable" : "Enable" }  </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="dash-head pt-4">
              <img src={require("assets/images/head-social.svg")} alt="" /> <h4 className="mb-0">Social Links</h4>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-field">
              <div className="form-group row mb-3">
                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Discord URL</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" name="discordUrl" defaultValue={values.discordUrl} onChange={handleChange} />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Twitter URL</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" name="twitterUrl" defaultValue={values.twitterUrl} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-field">
              <div className="form-group row mb-3">
                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Instagram URL</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" name="instaUrl" defaultValue={values.instaUrl} onChange={handleChange} />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Facebook URL</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" name="fbUrl" defaultValue={values.fbUrl} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="dash-head pt-4">
              <img src={require("assets/images/head-partner.svg")} alt="" /> <h4 className="mb-0">Partnerships</h4>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-field">
              <div className="form-group row mb-md-2">
                <label htmlFor="flipd" className="col-sm-2 col-form-label">Flipd</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" name="flipdId" defaultValue={values.flipdId} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-field">
              <div className="form-group row mb-md-2">
                <label htmlFor="stellar" className="col-sm-2 col-form-label">Stellar</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" name="stellarId" defaultValue={values.stellarId} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-field">
              <div className="form-group row mb-md-2">
                <label htmlFor="lumen" className="col-sm-2 col-form-label">Lumen</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" name="lumenId" defaultValue={values.lumenId} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 text-end">
            {/* <div className="d-inline-block mt-4 me-2">
              <a href="#" className="mb-3 px-5 cta-white">Reset</a>
            </div> */}
            <div className="form-cta-btn d-inline-block mt-4">
              <a href="#" onClick={saveProfile} className="mb-3 px-5 text-light">Save</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings;