import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as request from 'request';
import { FirebaseContext } from 'contexts/Firebase';
import Navbar from "components/Layout/Navbar";
import { showToastAlert, downloadFile, copyData } from 'Services/Utility';

const Nike = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [nikePayments, setNikePayments] = useState([]);

    useEffect(() => {
        document.body.classList.add('dash-bg');
        props.setOpenedSideMenu('nike');
    }, []);

    const getNikeOrderAPI = (idToken) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/nike/plans`,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'idToken': idToken, 'platform': 'web' })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Nike orders!';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    resolve(resData);
                }
            });
        });
    }

    const downloadBtnClick = (order) => {
        let strings = [];
        order.accounts.forEach(acnt => strings.push(`${acnt.username},${acnt.password},${acnt.outlookEmail},${acnt.outlookPassword},${acnt.outlookFirstName},${acnt.outlookLastName},${acnt.outlookDoB}`));
        if (strings.length && order.authToken) {
            strings.unshift('Username,Password,Outlook Email,Outlook Password,Outlook First Name,Outlook Last Name,Outlook DOB');
            downloadFile(order.authToken + ".csv", strings.join('\n'));
        }
    }

    const copyBtnClick = (order) => {
        let strings = [];
        console.log(order.accounts)
        order.accounts.forEach(acnt => strings.push(`${acnt.username},${acnt.password},${acnt.outlookEmail},${acnt.outlookPassword},${acnt.outlookFirstName},${acnt.outlookLastName},${acnt.outlookDoB}`));
        if (strings.length) {
            copyData(strings.join('\n'), 'Accounts');
        }
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/', { 'from': 'nike' });
                        return;
                    }

                    let idToken = await firebase.auth.currentUser.getIdToken(true);
                    let response = await getNikeOrderAPI(idToken);
                    setNikePayments(response.data || []);

                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <div id="page-content-wrapper" className="extra-p">

            <Navbar toggleSideMenu={props.toggleSideMenu} />

            <div className="dash-inner table-part">
                <div className="row">
                </div>
                <div className="row">
                    <div className="col-12 mt-5">

                        <div className="dash-box position-relative mt-2">

                            <div className="dash-head mb-4">
                                <h2 className="mb-0">Nike Plans</h2>
                            </div>

                            <div className="table-responsive mt-2">
                                <table className="table table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: '20%' }}>Auth Token</th>
                                            <th scope="col" style={{ width: '20%' }}>Plan</th>
                                            <th scope="col" style={{ width: '20%' }}>Purchase Date</th>
                                            <th scope="col" style={{ width: '10%' }}>Paid</th>
                                            <th scope="col" style={{ width: '20%' }}>Accounts</th>
                                            <th scope="col" style={{ width: '10%', textAlign: 'center' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {nikePayments && nikePayments.length > 0 && nikePayments.map(order => {
                                            return (
                                                <tr>
                                                    <td style={{ verticalAlign: 'middle' }} scope="row">{order.authToken}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{order.planName}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{new Date(parseInt(order.createdAt) * 1000).toDateString()}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>$ {order.paid}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{order.accounts.length} Accounts</td>
                                                    <td className='d-flex justify-content-center'>
                                                        <button className="btn" onClick={(e) => copyBtnClick(order)}><img src={require("assets/images/copy-btn.svg")} alt="" /></button>
                                                        <button className="btn" onClick={(e) => downloadBtnClick(order)}><img src={require("assets/images/export-btn.svg")} alt="" /></button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nike;
