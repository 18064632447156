import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts';
import {
    PageLoader,
    showToastAlert
} from '../Services/Utility';


const Forgot = () => {
    const { firebase, setUser } = useContext(FirebaseContext);
    const history = useHistory();
    const [values, setValues] = useState({
        email: ''
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                if (user) {
                    history.push('/');
                    return;
                } else {
                    setLoading(false);
                }
            });
        })();
        document.body.classList.add('login-page');
    }, [history, firebase.auth]);

    useEffect(() => {
        return () => {
            document.body.classList.remove('login-page');
        }
    }, []);

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({
            ...values,
            [event.target.name]: event.target.value
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        firebase.doPasswordReset(values.email)
            .then(() => {
                showToastAlert('Success', 'Password reset link has been sent to your email address!');
                history.push('/login');
            })
            .catch(error => {
                showToastAlert('Error', error.message);
            });
    }

    return (
        <>
            {loading && <PageLoader />}
            <section>
                <div className="form-part">
                    <div className="form-inner">
                        <h2 className="mb-5">Forgot Password</h2>
                        <p>
                            Please enter email address to reset password. StellaProxies will send reset password link on provided email address.
                        </p>

                        <div className="form-field">
                            <div className="mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    aria-describedby="emailHelp"
                                    placeholder="example@gmail.com"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-cta-btn" style={{ marginTop: '5rem' }}>
                                <a href="#" className="mb-3" onClick={handleSubmit}>Send Password Reset Link</a>
                                <a href="/register" className="mb-2">Create New Account</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Forgot;