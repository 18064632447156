import React, { useEffect } from 'react';
const Privacy = () => {

    useEffect(() => {
        // code to run after render goes here
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    return (
        <div id="page-content-wrapper" className="extra-p">

            <div className="dash-inner">

                <div className="row mb-4">
                    <div className="col-sm-12">
                        <h1 className="fw-bold text-center mt-2 text-blue">Privacy Notice</h1>
                    </div>
                </div>

                <div className="wow animate__animated animate__fadeInDown mb-4" data-wow-duration="2s">
                    <div className="plan-price-inner" style={{ fontSize: '1rem', letterSpacing: '0.5px', lineHeight: '1.5' }}>
                        <h5 className="fw-bold">LAST REVISED: November 29, 2021</h5><br />
                        <p className="description">This Privacy Notice: (i) describes how We collect, transfer and manage the personal information You voluntarily provide Us when You access or use Our Services; and (ii) identifies certain options You may have with respect to Your personal information and privacy.</p>
                        <p className=""><b>BY PROCEEDING TO ACCESS AND USE OUR SERVICES YOU AGREE TO THE TERMS OF THIS PRIVACY NOTICE AND THEREBY CONSENT TO THE WAY WE PROCESS YOUR PERSONAL INFORMATION AND NON-PERSONAL INFORMATION. ALL CAPITALIZED TERMS USED IN THIS PRIVACY NOTICE WILL HAVE THE MEANING SET FORTH IN ELSEWHERE IN THE AGREEMENT.</b></p>
                        <h6 className="fw-bold">1. Privacy Notice Changes</h6>
                        <p className="description">We may modify this Privacy Notice from time to time to comply with Our legal requirements and to account for changes to Services. The Last Revised date on the top left of the Privacy Notice will identify the date that this Privacy Notice was last updated.</p>
                        <h6 className="fw-bold">2. Legitimate Basis</h6>
                        <p className="description">You and Us have entered into a binding Agreement. In order to meet Our contractual obligations under the Agreement, We have a legitimate business interest to collect, process and share Your personal information as set forth herein.  We are unable to provide You Services without use of Your personal information. If You do not want Us to use Your personal information, please do not access or use Our Services.</p>
                        <h6 className="fw-bold">3. PERSONAL INFORMATION</h6>
                        <p className="description">Personal information is information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household. The following are personal information categories We may collect from You:</p>
                        <ol type='i' style={{ marginLeft: '40px' }}>
                            <li><span className='fw-bold'><u>Profile Information</u></span>: Name, postal address, unique personal identifier, telephone number, online identifier, Internet Protocol address, email address, company name, or other similar information.</li>
                            <li><span className='fw-bold'><u>Protected Classification Information</u></span>: Sex, gender, medical condition (as it may relate to the provisioning of Services) or age.</li>
                            <li><span className='fw-bold'><u>Transaction Information</u></span>: Services purchased, obtained, or considered, other purchasing or consuming histories or tendencies.</li>
                            <li><span className='fw-bold'><u>Personal Network Information</u></span>: Browsing history, search history, information regarding Your interaction with Services, advertisements and/or promotions (please also  see Section 4(c) below).</li>
                            <li><span className='fw-bold'><u>Financial Information</u></span>: Bank account number, credit card number, debit card number, other financial information.</li>
                            <li><span className='fw-bold'><u>Inferences from the foregoing categories</u></span>: Additional demographic information reflecting Your preferences and characteristics.</li>
                        </ol>
                        <h6 className="fw-bold">4. Sources of Information</h6>
                        <p className="description">‍<span className='fw-bold'>a. <u>When You Contact Us</u></span>: We receive Your personal information when You contact Us, create an Account and/or when You acquire Services.</p>
                        <p className="description">‍<span className='fw-bold'>b. <u>Personal Network Information and Log Data</u></span>: We also collect Your public IP address when You access Services. We may use Your public IP address in order to determine whether certain requests are fraudulent or frivolous and We may automatically cross-reference Your public IP address with Your domain. When accessing Services from Your personal network Your IP address and any associated domain name will be treated as Your "Personal Network Information". "Log Data" does not include Personal Network Information.</p>
                        <h6 className="fw-bold">5. Use of Personal Information</h6>
                        <p className="description">We use personal information as follows:</p>
                        <ol type='a' style={{ marginLeft: '40px' }}>
                            <li>To keep You informed about Services, provide You with Services and provide You with customer service and other support related to Services;</li>
                            <li>To create, develop, operate, deliver, and improve Services;</li>
                            <li>To protect Our Services, and other Users, as well as to enforce Our rights under the Agreement (specifically for anti-fraud purposes and to prevent illegal or unauthorized activity);</li>
                            <li>To verify that Your identify, Account, payment method, and other relevant information related to Your access and use of Services;</li>
                            <li>For internal purposes such as auditing, data analysis, to meet our business obligations and research; and/or</li>
                            <li>To generate aggregated data which is created after removing personal information that identify You. We may combine that information with information We collect from other Users to improve the quality and value of Services and to analyze and understand how Services are used.</li>
                        </ol>
                        <h6 className="fw-bold">6. How we share Your Personal information</h6>
                        <p className="description">‍<span className='fw-bold'>a. <u>Third Party Service Providers</u></span>: We share Your personal information with vendors to who assist Us with making Services available to You, conduct quality assurance testing; provide technical support; and/or to provide other Services to Us (“Third Party Service Providers”). Except as otherwise stated in this Privacy Notice, Third-Party Service Providers are required to use Your personal information other than to provide requested Services.</p>
                        <p className="description">‍<span className='fw-bold'>b. <u>Affiliates</u></span>: We may share some or all of Your personal information with Our affiliates, in which case We will require Our affiliates to honor this Privacy Notice.</p>
                        <p className="description">‍<span className='fw-bold'>c. <u>Legal Compliance</u></span>: We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We may disclose Your personal information to government or law enforcement officials or private parties in response to lawful requests when We believe disclosure or sharing is necessary to comply with any legal obligation, enforce the Agreement, respond to claims and legal process, protect Our rights or a third party User, to protect the safety of the public or any person, or to prevent or stop any illegal, unethical or legally actionable activity.</p>
                        <p className="description">‍<span className='fw-bold'>d. <u>Corporate Restructuring</u></span>: We may share some or all of Your personal information in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction, or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of Our business or assets. In the event of an insolvency, bankruptcy, or receivership, personal information may also be transferred as a business asset.</p>
                        <p className="description">‍<span className='fw-bold'>e. <u>Legitimate Business Purposes</u></span>: We may also disclose Your personal information when it may be necessary for other legitimate purposes as reasonably determined by Us.</p>
                        <h6 className="fw-bold">7. Cookies, Tracking technologies and Behavioral advertising</h6>
                        <p className="description">‍<span className='fw-bold'>a. <u>Cookies and Tracking Technologies</u></span>: We and Our partners, affiliates, analytics and/or Third Party Service Providers use cookies or other tracking technologies as pixel tags and web beacons. These are used in storing content information and preferences, analyze trends, administer and maintain Services, monitor User interaction with Services and to gather demographic information about Our User base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis. We use cookies to remember User settings for authentication. Users can control the use of cookies at the individual browser level. If You reject cookies, You may still access and use Services, but Your ability to use some features will be fundamentally diminished. These technologies help Us better understand User behavior, tell Us which parts of Services are visited, and facilitate and measure the effectiveness of advertisements and web searches. We treat information collected by cookies and other technologies as non personal information.</p>
                        <p className="description">‍<span className='fw-bold'>b. <u>Behavioral Advertising</u></span>: We may participate in third party behavioral advertising. This means that a third party may use technology to collect information about Your use of Our Services so that other parties can provide advertising about products and services tailored to Your interests. If You do not want third parties to collect and use Your information in this manner You may opt-out at the <a href='http://optout.aboutads.info/' target='__blank'>Digital Advertising Alliance</a> in the U.S.A.</p>
                        <h6 className="fw-bold">8. Additional Choices</h6>
                        <p className="description">You have the following choices with respect to your personal information:</p>
                        <p className="description">‍<span className='fw-bold'>a. <u>Profile Management</u></span>: If You want to view, delete or modify Your personal information, you may do so by logging into Your Account.  If for some reason You are unable to manage Your Account on Your own please contact use sending an e-mail to <a href='mailto:admin@stellaproxies.com'>admin@stellaproxies.com</a> for assistance.  All requests must include:  (i) “INFORMATION REQUEST” in the e-mail subject line; (ii) Your full name; and (iii) what information of Yours you want to view, delete or modify.  We reserve the right to verify the identity of any person making a request and/or to delete or modify their Account.  If We delete any information, it will be deleted from the active database, but may remain in Our archives.  We will have no liability of any kind resulting from false or erroneous requests or for any modification or deletion made by Us for any reason.  We store personal information for only as long as reasonably necessary to fulfill the purposes described above, as we determine is necessary for business records, and as required under applicable law.</p>
                        <h6 className="fw-bold">9. International Users</h6>
                        <p className="description">We are based in New York City, United States of America (“U.S.A.”) and the information We collect is governed by New York State and applicable Federal Law.  If You are accessing Services from outside of the U.S.A., please be aware that information collected through Services will be transferred, processed, and used in the U.S.A.  The data protection laws in the U.S.A. may be different from those of the country in which you are located.  Your use of the Services or providing Us with any information therefore constitutes your consent to the transfer to, and processing, usage, sharing and storage of Your information, including personal information, in the U.S.A. as set forth in this Privacy Notice.</p>
                        <h6 className="fw-bold">10. California privacy rights</h6>
                        <p className="description">‍<span className='fw-bold'>a. <u>California Shine the Light Act</u></span>: Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to request from a business with whom the California resident has an established business relationship, certain information with respect to the types of personal information the business shares with third parties for direct marketing purposes by such third party and the identities of the third parties with whom the business has shared such information during the immediately preceding calendar year.</p>
                        <p className="description">To request a copy of the information disclosure provided by Us pursuant to Section 1798.83 of the California Civil Code, Your request must include contact Us at the e-mail or regular mail address specified in the Contacting Us section below with “California Privacy Request” in the first line. Please note that under this law, We are not required to respond to Your request more than once in a calendar year, nor are We required to respond to any request that is not sent to the designated e-mail or mailing address.</p>
                        <p className="description">‍<span className='fw-bold'>b. <u>California Do Not Track Disclosure</u></span>: Do Not Track is a privacy preference that some users may set in their web browsers. When a user turns on the Do Not Track signal, the browser sends a message to websites requesting them not to track the user. At this time, We do not recognize or respond to Do Not Track browser settings or signals and We will still receive information. As a result, We may still collect information about You and Your internet activity, even if You have turned on the Do Not Track signal.</p>
                        <p className="description">It is possible that some or all of Our third-party advertising partners or members of their affiliate network may participate in consumer opt-out programs. To learn more about internet-based advertising and consumer opt-out programs go to <a href='http://aboutads.info/choices' target='__blank'>http://aboutads.info/choices</a> or <a href='http://www.networkadvertising.org/choices' target='__blank'>http://www.networkadvertising.org/choices</a>.</p>
                        <h6 className="fw-bold">11. Information Security and Confidentiality</h6>
                        <p className="description">No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, although We comply with Our legal obligations in respect of the security of Your personal information We cannot guarantee its absolute security. We use (and require Our Third Party Service Providers to maintain) generally accepted standards of organizational, administrative, physical, procedural, and technological measures designed to protect Your information from improper loss or misuse, and unauthorized access, disclosure, alteration, and destruction during processing. If You have any questions about the security of Your personal information, You can contact Us at the e-mail or regular mail address specified in the Contacting Us section below.</p>
                        <h6 className="fw-bold">12. Data Retention</h6>
                        <p className="description">We will only retain Your personal information for as long as necessary to fulfill the purposes We collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal information, We consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of Your personal information, the purposes for which We process Your personal information and whether We can achieve those purposes through other means, and the applicable legal requirements. Our default retention period is 1 years.</p>
                        <h6 className="fw-bold">13. Minors</h6>
                        <p className="description">Our privacy practices are consistent with the Federal Children’s Online Privacy Protection Act (“COPPA”) and We will not knowingly request or collect personal information from any child under the age of majority.  If You are a minor, Your parent(s) or guardian(s) must complete the registration process, in which case he/she/they will take full responsibility for all obligations set forth herein.  BY CREATING A PROFILE, YOU REPRESENT THAT YOU ARE AN ADULT AND ARE EITHER ACCEPTING THIS AGREEMENT ON BEHALF OF YOURSELF OR ON BEHALF OF YOUR CHILD, IN WHICH CASE YOU AGREE TO BE PERSONALLY BOUND BY ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN.</p>
                        <h6 className="fw-bold">14. Contacting us</h6>
                        <p className="description">If You have questions or complaints about how We use Your personal information, please contact Us at: <a href='mailto:admin@stellaproxies.com'>admin@stellaproxies.com</a>.</p>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;