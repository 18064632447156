import { WOW } from 'wowjs';


class DOM {
  constructor() {
    new WOW({ live: false }).init();
  }

  setMainSectionMinHeight() {
    let _header = (document.getElementsByTagName('header')[0] && document.getElementsByTagName('header')[0].offsetHeight) || 0;
    let _footer = (document.getElementsByTagName('footer')[0] && document.getElementsByTagName('footer')[0].offsetHeight) || 0;
    let _window_height = window.outerHeight;
    let _main_height = _window_height - (_header + _footer + 72);
    document.getElementsByTagName('main')[0].style.minHeight = `${_main_height}px`;
  }

  init() {
    document.addEventListener('DOMContentLoaded', this.setMainSectionMinHeight);
    window.addEventListener("resize", this.setMainSectionMinHeight);
  }
}

export default DOM;