import React, { useState, useContext, useEffect } from 'react';
import { Container } from 'reactstrap';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from "react-router-dom";

// Contexts
import { FirebaseContext } from '../src/contexts';

// Core Components
import Header from 'components/Layout/Header';

// Page Components
import Home from 'views/Home';
import Dashboard from 'views/Dashboard';
import ISP from 'views/ISP';
import Pricing from 'views/Pricing';
import Orders from 'views/Orders';
import Settings from 'views/Settings';
import ForgotPassword from 'views/ForgotPassword';
import Login from 'views/Login';
import Register from 'views/Register';
import TOS from 'views/TOS';
import Privacy from 'views/Privacy';
import ContactUS from 'views/ContactUs';
import ProxyProduct from 'views/ProxyProduct';
import YsProduct from 'views/YsProduct';
import NikeProduct from 'views/NikeProduct';
import Nike from 'views/Nike';
import BlogPage from 'views/Blog';

import IspPage from 'views/products/ISP';
import ResidentialPage from 'views/products/Residential';
import CaptchaPage from 'views/products/Captcha';
import NikePage from 'views/products/Nike';
import BulkPage from 'views/products/Bulk';
import SneakerPage from 'views/products/Sneaker';


const Main = withRouter(({ location }) => {

  const { firebase } = useContext(FirebaseContext);
  const [isOpen, setIsOpen] = useState(false);
  const [openedSideMenu, setOpenedSideMenu] = useState('dashboard');

  const toggleSideMenu = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (user) {
            const lm_data = window.lm.getReferralData();
            if (lm_data) {
              firebase.db.ref(`users/${firebase.auth.currentUser.uid}/`).update({ f_data: lm_data });
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    })();
  }, [firebase.auth]);

  return (
    <>
      <div className={`d-flex ${isOpen ? 'toggled' : ''}`} id="wrapper" >
        {
          location.pathname !== '/login' &&
          location.pathname !== '/register' &&
          location.pathname !== '/forgot-password' &&
          location.pathname !== '/privacy' &&
          location.pathname !== '/tos' &&
          location.pathname !== '/' &&
          !location.pathname.includes('/p/') &&
          !location.pathname.includes('/products/') &&
          !location.pathname.includes('/blog') &&
          <Header toggleSideMenu={setIsOpen} activedSideMenu={openedSideMenu} />
        }
        <Container fluid className="p-0" id='mainContainer'>
          <main>
            <Switch>
              <Route
                exact
                path="/"
                render={props => <Home {...props} toggleSideMenu={setIsOpen} />}
              />
              <Route
                exact
                path="/dashboard"
                render={props => <Dashboard {...props} toggleSideMenu={toggleSideMenu} setOpenedSideMenu={setOpenedSideMenu} />}
              />
              <Route
                exact
                path="/residential"
                render={props => <Dashboard {...props} toggleSideMenu={toggleSideMenu} setOpenedSideMenu={setOpenedSideMenu} />}
              />
              <Route
                path={["/login", "/p/login"]}
                render={props => <Login {...props} toggleSideMenu={setIsOpen} />}
              />
              <Route
                path="/register"
                render={props => <Register {...props} />}
              />
              <Route
                path="/forgot-password"
                render={props => <ForgotPassword {...props} />}
              />
              <Route
                path="/isp"
                render={props => <ISP {...props} toggleSideMenu={toggleSideMenu} setOpenedSideMenu={setOpenedSideMenu} />}
              />
              <Route
                path="/nike"
                render={props => <Nike {...props} toggleSideMenu={toggleSideMenu} setOpenedSideMenu={setOpenedSideMenu} />}
              />
              <Route
                path="/pricing"
                render={props => <Pricing {...props} toggleSideMenu={toggleSideMenu} setOpenedSideMenu={setOpenedSideMenu} />}
              />
              <Route
                path="/orders"
                render={props => <Orders {...props} toggleSideMenu={toggleSideMenu} setOpenedSideMenu={setOpenedSideMenu} />}
              />
              <Route
                path="/settings"
                render={props => <Settings {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/contactus"
                render={props => <ContactUS {...props} toggleSideMenu={toggleSideMenu} setOpenedSideMenu={setOpenedSideMenu} />}
              />
              <Route
                path="/tos"
                render={props => <TOS {...props} />}
              />
              <Route
                path="/privacy"
                render={props => <Privacy {...props} />}
              />

              <Route
                path={"/blog/:slug?"}
                render={props => <BlogPage {...props} toggleSideMenu={setIsOpen} />}
              />

              <Route
                path={["/p/IS-Y", "/p/is-y", "/p/IS-X", "/p/is-x", "/p/IS-3", "/p/is-3", "/p/dc", "/p/pro", "/p/premium"]}
                render={props => <ProxyProduct {...props} toggleSideMenu={setIsOpen} />}
              />

              <Route
                path={"/p/ys"}
                render={props => <YsProduct {...props} toggleSideMenu={setIsOpen} />}
              />
              <Route
                path={"/p/nike"}
                render={props => <NikeProduct {...props} toggleSideMenu={setIsOpen} />}
              />

              <Route
                path={"/products/isp-proxies"}
                render={props => <IspPage {...props} toggleSideMenu={setIsOpen} />}
              />
              <Route
                path={"/products/residential-proxies"}
                render={props => <ResidentialPage {...props} toggleSideMenu={setIsOpen} />}
              />
              <Route
                path={"/products/captcha-proxies"}
                render={props => <CaptchaPage {...props} toggleSideMenu={setIsOpen} />}
              />
              <Route
                path={"/products/nike"}
                render={props => <NikePage {...props} toggleSideMenu={setIsOpen} />}
              />
              <Route
                path={"/products/bulk-proxies"}
                render={props => <BulkPage {...props} toggleSideMenu={setIsOpen} />}
              />
              <Route
                path={"/products/sneaker-proxies"}
                render={props => <SneakerPage {...props} toggleSideMenu={setIsOpen} />}
              />

              <Redirect to='/404' />

            </Switch>
          </main>
        </Container>
      </div>
    </>
  )
});

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  )
}

export default App;
