import React, { useState } from "react";

const FAQ = ({ qa }) => {
  const [faqs, setFaqs] = useState(qa);

  const toggle = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="faq-wrap">
        {faqs.map(({question, answer, open}, index) => (
          <div key={`q-${index}`} className={"faq " + (open ? "open" : "")} onClick={() => toggle(index)}>
              <h2>
                {question}
                <img src={require("assets/images/chevron-right.svg")} alt="" />
              </h2>
              <div className="answer">{answer}</div>
          </div>
        ))}
    </div>
  );
};

export default FAQ;