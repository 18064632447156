import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from 'contexts/Firebase';
import Navbar from "components/Layout/Navbar";
import * as request from 'request';
import $ from 'jquery';
import {
    showToastAlert
} from 'Services/Utility';

const ContactUS = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const [values, setValues] = useState({ name: '', subject: '', message: '', email: '' });
    const [hideNavbar, setHideNavbar] = useState(true);

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
        props.setOpenedSideMenu('contactus');
    }, []);

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        // history.push('/', { 'from': 'contactus' });
                        // return;
                        setHideNavbar(true);
                        $('#logoDiv').removeClass('d-none');
                    }
                    else {
                        setValues({ email: user.email });
                        setHideNavbar(false);
                        $('#logoDiv').addClass('d-none');
                    }
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                }
            });
        })();
    }, [firebase.auth]);

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({
            ...values,
            [event.target.name]: event.target.value
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (values.name.trim() === '' || values.subject.trim() === '' || values.message.trim() === '' || values.email.trim() === '') {
            return showToastAlert('Error', 'All fields are required');
        }
        await sendContactData();
        setValues({ name: '', subject: '', message: '', email: '' });
        $('#name, #subject, #message, #email').val('');
    }

    const sendContactData = () => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/data/contact`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while sending query';
                    showToastAlert('Error', errorMessage);
                    return resolve(false);
                } else {
                    showToastAlert('Success', 'Query sent successfully');
                    return resolve(true);
                }
            });
        });
    }

    return (
        <div id="page-content-wrapper" className="extra-p">
            {
                !hideNavbar &&
                <Navbar toggleSideMenu={props.toggleSideMenu} />
            }
            <div className="sidebar-heading text-center my-3 d-none" id='logoDiv'>
                <a href='/'>
                    <img src={require("assets/images/stella.png")} alt="" style={{ width: '5rem', marginTop: '1rem' }} />
                </a>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <h1 className="fw-bold text-center mt-2 text-blue">Contact Us</h1>
                    <p className="text-center text-blue">Contact our support teams by fill out the form below</p>
                </div>
            </div>

            <div className="row px-4">
                <div className="form-inner" style={{ maxWidth: '530px' }}>
                    <div className="form-field">
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                aria-describedby="nameHelp"
                                placeholder="Enter your name"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                aria-describedby="nameHelp"
                                placeholder="Enter your email"
                                defaultValue={user ? user.email : ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="subject"
                                name="subject"
                                aria-describedby="subjectHelp"
                                placeholder="Subject"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <textarea
                                className="form-control"
                                rows="8"
                                id="message"
                                name="message"
                                aria-describedby="messageHelp"
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <div className="form-cta-btn" style={{ marginTop: '4rem' }}>
                            <a href="#" onClick={handleSubmit}>Submit</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUS;