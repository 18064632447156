import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts/Firebase';
import Navbar from "components/Layout/Navbar";
import {
  CheckoutModal,
  showToastAlert,
  HalfDoughnut,
  copyData,
  downloadFile
} from 'Services/Utility';


const ISP = (props) => {
  const { user, firebase } = useContext(FirebaseContext);
  const history = useHistory();
  const [hasDiscordId, setHasDiscordId] = useState(false);
  const [dcPlans, setDCPlans] = useState([]);
  const [userDCPlans, setUserDCPlans] = useState([]);
  const [dcAuthToken, setDCAuthToken] = useState("");
  const [totalProxies, setTotalProxies] = useState([]);
  const [checkoutModals, setCheckoutModals] = useState([]);
  const [purchasePlan, setPurchasePlan] = useState({});
  const [categories, setCategories] = useState({});
  // const [selectedCategory, setSelectedCategory] = useState();
  const [purchaseSubplan, setPurchaseSubplan] = useState();
  const [isSwapping, setIsSwapping] = useState(0);

  const openCheckoutModal = async (planType, planID, subPlanID, renew = false) => {
    try {
      setCheckoutModals([]);

      if (!user) {
        showToastAlert('Error', 'Log in required to purchase a plan');
        return;
      }

      let showModal = true;
      let planData = dcPlans.find(plan => plan.planID === planID);
      if (!planData) return;

      const subPlan = planData.subPlans[subPlanID];
      if (!subPlan || !subPlan.active) return;

      let checkoutPlanPrice = subPlan.price;
      let planPrice = `Plan Price $${checkoutPlanPrice}`;
      setCheckoutModals([
        <CheckoutModal
          showModal={showModal}
          checkoutPlanID={planID}
          checkoutsubPlanID={subPlanID}
          checkoutPlanType={planType}
          checkoutPlanPrice={checkoutPlanPrice}
          planPrice={planPrice}
          planData={planData}
          updatePlanRefID={renew && dcAuthToken.updatePlanRefID ? dcAuthToken.updatePlanRefID : null}
          key={Math.random()}
          firebase={firebase}
        />
      ]);
    } catch (error) {
      showToastAlert('Error', error.message);
    }
  }

  const generateStripeLink = async () => {
    console.log("Hi");
    let idToken = await firebase.auth.currentUser.getIdToken(true);
    let link = await manageSubscription(idToken);
    if (link && link.status == "success" && link.session && link.session.url) {
      window.open(link.session.url, "_blank");
    }

  }

  const manageSubscription = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/stripe/billingDetails`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching subscription detail!';
          console.log('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const swappingProxiesFor = (idToken, authtoken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/isp/refresh`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          idToken, authtoken
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while swapping proxies!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const getDCPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/isp/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'idToken': idToken, 'platform': 'web'
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching ISP proxies!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const renewPlan = () => {
    if (!hasDiscordId) {
      return showToastAlert('Error', "Please verify your discord account");
    }
    openCheckoutModal('isp', dcAuthToken.planID, dcAuthToken.subPlanID, true)
  }

  const newPlan = () => {
    if (!hasDiscordId) return showToastAlert('Error', "Please verify your discord account");
    if (!purchaseSubplan) return showToastAlert('Info', "Please select your plan");
    if (!purchasePlan.active) return;
    openCheckoutModal('isp', purchasePlan.planID, purchaseSubplan)
  }

  // const changeCategory = (event) => {
  //   console.log(selectedCategory);
  //   console.log(event.target.value);
  //   setSelectedCategory(event.target.value);
  // }

  const copyProxies = () => {
    if (dcAuthToken && dcAuthToken.planData) {
      copyData(dcAuthToken.planData.proxies.map(x => x + ':' + dcAuthToken.planData.username + ':' + dcAuthToken.planData.password).join('\r\n'));
    }
  }

  const downloadProxies = () => {
    if (dcAuthToken && dcAuthToken.planData) {
      downloadFile(dcAuthToken.authToken + ".txt", dcAuthToken.planData.proxies.map(x => x + ':' + dcAuthToken.planData.username + ':' + dcAuthToken.planData.password).join('\r\n'));
    }
  }

  const swapProxies = async () => {
    if (dcAuthToken && dcAuthToken.authToken) {
      if (dcAuthToken.hasOwnProperty('canSwap') && !dcAuthToken.canSwap) {
        return showToastAlert('Error', 'Proxies have already been swapped');
      }
      const swapDiv = document.getElementById('swap');
      const swapH5 = document.getElementById('swapText');
      if (isSwapping === 0) {
        swapDiv.classList.remove('swap');
        swapDiv.classList.add('swaping');
        swapH5.innerText = 'Swapping';
        setIsSwapping(1);

        const idToken = await firebase.auth.currentUser.getIdToken(true);
        const result = await swappingProxiesFor(idToken, dcAuthToken.authToken);
        if (result && result.planData) {
          dcAuthToken.canSwap = result.canSwap;
          dcAuthToken.lastSwap = result.lastSwap;
          dcAuthToken.planData = result.planData;
          showToastAlert('Success', 'Proxies are swapped successfully');
        }
        setIsSwapping(0);
        swapDiv.classList.remove('swaping');
        swapDiv.classList.add('swap');
        swapH5.innerText = 'Swap';
      }
    }
  }

  const sortSubPlansIds = (subPlans) => {
    return Object.keys(subPlans).sort((subId1, subId2) => parseInt(subPlans[subId1].numberOfProxies) - parseInt(subPlans[subId2].numberOfProxies));
  }

  useEffect(() => {
    // code to run after render goes here
    document.body.classList.add('dash-bg');
    props.setOpenedSideMenu('isp');
  }, []);

  useEffect(() => {
    if (dcAuthToken.authToken) {
      const swapDiv = document.getElementById('swap');
      const swapH5 = document.getElementById('swapText');
      if (!dcAuthToken.hasOwnProperty('canSwap') || (dcAuthToken.hasOwnProperty('canSwap') && dcAuthToken.canSwap)) {
        swapDiv.classList.remove('d-none');
        swapDiv.classList.remove('swap-disable');
        swapH5.innerText = 'Swap';
      }
      else if (dcAuthToken.hasOwnProperty('canSwap') && !dcAuthToken.canSwap) {
        swapDiv.classList.remove('d-none');
        swapDiv.classList.add('swap-disable');
        swapH5.innerText = 'Swapped';
      }
    }
  }, [dcAuthToken, dcAuthToken.canSwap]);

  useEffect(() => {
    setPurchaseSubplan('');
  }, [purchasePlan]);

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (!user) {
            history.push('/', { 'from': 'isp' });
            return;
          }
          let idToken = await firebase.auth.currentUser.getIdToken(true);
          let plans = await Promise.all([getDCPlans(idToken)]);

          let planDetails = (await firebase.db.ref(`plans/isp`).once('value')).val();
          planDetails = { ...planDetails };
          const mainPlanActive = planDetails.active || false;
          const categories = { ...planDetails['categories'] };
          delete planDetails['categories'];
          delete planDetails['active'];

          setCategories(categories);

          let cats = {}, dcplans = {}, onlyBotPlanIds = [];
          for (const planId in planDetails) {
            const plan = planDetails[planId];
            plan.active = mainPlanActive && plan.active ? true : false;
            if (plan.onlybot) onlyBotPlanIds.push(planId);
            if (!plan.hide && !plan.onlybot && !plan.bulk) {
              plan.planID = planId;
              dcplans[planId] = plan;
            }
          }

          const dcPlanList = Object.keys(dcplans).map(key => ({ ...dcplans[key] })).sort((a, b) => (a.order || 0) - (b.order || 0));
          setDCPlans(dcPlanList);

          if (dcPlanList.length) {
            setPurchasePlan(dcPlanList[0]);
          }
          else {
            setPurchasePlan({});
          }

          if (plans[0] && plans[0].status === 'success') {
            let dcData = plans[0];
            if (Object.keys(dcData.data).length) {
              let totalProxies = 0;
              let userDcPlans = Object.keys(dcData.data).map(key => ({
                ...dcData.data[key],
                authToken: key
              }));
              // userDcPlans = userDcPlans.filter(x => !onlyBotPlanIds.includes(x.planID));
              setUserDCPlans(userDcPlans);
              userDcPlans.forEach(plan => {
                totalProxies = totalProxies + plan.planData.proxies.length;
              });
              setTotalProxies(totalProxies);
              setDCAuthToken(userDcPlans[0]);
            }
          }

          const discordId = (await firebase.db.ref(`users/${user.uid}/discordId`).once('value')).val();
          if (discordId && discordId !== '') {
            setHasDiscordId(true);
          }

        } catch (error) {
          showToastAlert('Error', error.message);
        }
      });
    })();
  }, [history, firebase.auth]);

  return (
    <div id="page-content-wrapper" className="extra-p">

      <Navbar toggleSideMenu={props.toggleSideMenu} />

      <div className="dash-inner">
        {checkoutModals.map(checkoutModal => (
          checkoutModal
        ))}

        <div className="row">
          <div className="col-lg-6">
            <div className="dash-box position-relative">

              <div className="isp-img-top">
                <img src={require("assets/images/isp-bg-img.png")} alt="" />
              </div>

              <div className="d-flex align-items-center gap-5 up-z">
                <div className="dash-head mb-0">
                  <h4 className="mb-0">Your Plans</h4>
                </div>
              </div>

              <p className="text-blue mb-0 mt-4 up-z">Total ISP Proxies</p>
              <h1 className="text-blue fw-bold display-5 up-z">{totalProxies}</h1>
              <div className="row mt-2 up-z">
                {userDCPlans && userDCPlans.length > 0 && userDCPlans.map((plan) => {
                  return (
                    <div className="col-6 col-sm-3" onClick={setDCAuthToken.bind(this, plan)} style={{ minWidth: '120px' }}>
                      <div className={plan.authToken == dcAuthToken.authToken ? "inner-isp-box active" : "inner-isp-box"}>
                        <h5 className="text-blue">{plan.location}</h5>

                        <span className="display-6 fw-bold text-blue">{plan.planData.proxies.length} </span> <span className="text-blue">Proxies</span>

                        <div className="isp-img mt-2 text-center">
                          <HalfDoughnut key={Math.random()} dataset={[parseInt(plan.planData.expiryhours) - parseInt(plan.planData.timeusage), parseInt(plan.planData.timeusage)]} ></HalfDoughnut>
                        </div>
                        {/* parseInt(plan.planData.expiryhours) === 1000000 ? 'LifeTime' :  */}
                        {
                          plan.subscriptionCurrentEnd &&
                          <a href="#" className="text-center text-blue text-decoration-none d-inline-block mt-2">{Math.floor((parseInt(plan.subscriptionCurrentEnd) * 1000 - parseInt(new Date().getTime())) / (1000 * 60 * 60 * 24))} Days remaining till renewal</a>
                        }
                        {
                          !plan.subscriptionCurrentEnd &&
                          <a href="#" className="text-center text-blue text-decoration-none d-inline-block mt-2">{Math.floor((parseInt(plan.planData.expiryhours) - parseInt(plan.planData.timeusage)) / 24)} Days remaining till renewal</a>
                        }
                      </div>
                    </div>
                  )
                })
                }

                {!userDCPlans || userDCPlans.length == 0 &&
                  <div className="col-6 col-sm-3">
                    <div className="inner-isp-box active">
                      <h5 className="text-blue">No, plan!</h5>

                      <span className="display-6 fw-bold text-blue">0 </span> <span className="text-blue">Proxies</span>

                      <div className="isp-img mt-2 text-center">
                        <HalfDoughnut key={Math.random()} dataset={[0, 100]} ></HalfDoughnut>
                      </div>
                      <a href="#" className="text-center text-blue text-decoration-none d-inline-block mt-2">0 Days Remaining</a>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="dash-head mb-3">
              <img src={require("assets/images/promotion.svg")} alt="" /> <h4 className="mb-0">Plans</h4>
            </div>
            <div className="isp-new-nav">
              <div className="proxy-plans dash-nav">
                <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  {dcPlans.map((plan, idx) => {
                    return (
                      <li className="nav-item" role="presentation" onClick={setPurchasePlan.bind(this, { ...plan })}>
                        <button className={purchasePlan && plan.planID === purchasePlan.planID ? "nav-link active" : "nav-link "} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home12" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{plan.name}</button>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="purchase-storage pt-1">
              <nav>
                <div className="nav nav-tabs border-0 sub-part-nav" id="nav-tab" role="tablist">
                  {purchasePlan && purchasePlan.subPlans && sortSubPlansIds(purchasePlan.subPlans).map(subId => {
                    const subplan = purchasePlan.subPlans[subId];
                    return (
                      <button className={purchaseSubplan === subId && purchasePlan.active && subplan.active ? "nav-link active" : "nav-link"} style={{ cursor: purchasePlan.active && subplan.active ? 'pointer' : 'not-allowed' }} onClick={purchasePlan.active && subplan.active ? setPurchaseSubplan.bind(this, subId) : null} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                        <div className="sub-part text-center">
                          <h2 className="fw-bold">{subplan.numberOfProxies}</h2>
                          <p className="my-2">/${subplan.price}</p>
                          <p className="mb-0">{categories[subplan.category].name}</p>
                          {(!purchasePlan.active || !subplan.active) &&
                            <p className="fw-bold mt-3 mb-0 text-danger" style={{ fontSize: '1.2rem' }}>Sold Out</p>
                          }
                        </div>
                      </button>
                    )
                  })}
                </div>
              </nav>

              <div className="form-field mt-4">
                <div className="row mb-3">
                  {/* <div className="col-4 col-sm-5 col-md-4 col-lg-3 col-xl-4" id='monthly-select'>
                    <select id="inputState" className="form-select" onChange={changeCategory} >
                      {categories && categories.length > 0 && categories.map(cat => {
                        return (<option value={cat.catId}>{cat.name}</option>)
                      })}
                    </select>
                  </div> */}
                  <div className="col-auto form-cta-btn d-flex align-items-center mt-4" style={{ columnGap: "10px" }}>
                    <a href="#" className="px-5" onClick={newPlan}>Quick Purchase</a> <a href="/pricing" className="text-blue ps-3 mb-0 bg-transparent">Compare</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="dash-box">
              <div className="d-flex align-items-center gap-5 up-z">
                <div className="dash-head mb-0">
                  <h4 className="mb-0">Renew Plan</h4>
                </div>
              </div>
              <div className="row position-relative">
                <div className="col-4 pt-3">
                  <img src={require("assets/images/req-isp.png")} alt="" className="img-fluid mt-3" />
                </div>
                <div className="col-8 d-flex align-items-center">
                  {!dcAuthToken.isSubscription || dcAuthToken.isSubscription === 'false' &&
                    <div className="gp-out">
                      <p className="text-blue mb-0 up-z">Your plan will expire in:</p>
                      <h1 className="text-blue fw-bold display-5 up-z">{dcAuthToken ? Math.floor((parseInt(dcAuthToken.planData.expiryhours) - parseInt(dcAuthToken.planData.timeusage)) / 24) : 0} Days </h1>
                      <div className="col form-cta-btn d-flex align-items-center mt-4" onClick={renewPlan}>
                        <a href="#" className="px-5">Renew Now</a>
                      </div>
                    </div>
                  }

                  {dcAuthToken.isSubscription && dcAuthToken.isSubscription !== 'false' &&
                    <div className="gp-out">
                      <p className="text-blue mb-0 up-z">Your subscription is Active</p>
                      <div className="col form-cta-btn d-flex align-items-center mt-4" onClick={generateStripeLink}>
                        <a href="#" className="px-5">Manage subscription</a>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="dash-head mb-2">
                <img src={require("assets/images/proxy-list.svg")} alt="" /> <h4 className="mb-0">Proxy List</h4>
              </div>
              <div className="dash-head mb-2 inner-isp-box swap d-none" id="swap" onClick={swapProxies}>
                <img src={require("assets/images/swap.svg")} alt="" /> <h5 className="mb-0" id="swapText">Swap</h5>
              </div>
            </div>
            <div className="proxy-details-part mt-2">
              <textarea style={{ width: '100%', height: '100%', border: 0 }} rows="9" value={dcAuthToken && dcAuthToken.planData ? dcAuthToken.planData.proxies.map(x => x + ':' + dcAuthToken.planData.username + ':' + dcAuthToken.planData.password).join('\r\n') : ''}>
              </textarea>
            </div>
            <div className="proxy-btn w-100 d-flex justify-content-between align-items-center mt-4">
              <a href="#" className="cta-white d-inline-block" onClick={copyProxies}>
                <img src={require("assets/images/copy-btn.svg")} className="pe-2" alt="" />
                Copy All
              </a>
              <a href="#" className="cta-white shadow-none bg-transparent ms-auto" onClick={downloadProxies}>
                <img src={require("assets/images/export-btn.svg")} className="pe-2" alt="" />
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ISP;