let firebaseConfig = {
  apiKey: "AIzaSyDUh5vOhfrVpL2MUed4yGVz7J2uO_5j79o",
  authDomain: "proxify-8980c.firebaseapp.com",
  databaseURL: "https://proxify-8980c-default-rtdb.firebaseio.com",
  projectId: "proxify-8980c",
  storageBucket: "proxify-8980c.appspot.com",
  messagingSenderId: "630900253828",
  appId: "1:630900253828:web:e941a072853ebf00748ed1",
  measurementId: "G-Q8K9L8FFFG"
  // measurementId: "G-HX52EMD08W"
};
export default firebaseConfig;