import React from "react";
import { Doughnut, Chart, Line } from 'react-chartjs-2';


class LineChart extends React.Component {
  constructor(props) {
    super(props);
    let text = props.text || '100%';
    let type = props.text || 'resi';
    let dataset = props.dataset || [0, 0];
    let labelset = props.labelset || [0, 0];
    let gradientColor = props.gradientColor || 'rgba(99, 131,223, 1)';
    let borderColor = props.borderColor || '#2d7dee';
    let pointColor = props.pointColor || '#80b6f4';

    let data = canvas => {
        const ctx = canvas.getContext('2d');
        var gradient1 = ctx.createLinearGradient(0, 0, 0, 170);
        gradient1.addColorStop(0, gradientColor);        
        gradient1.addColorStop(1, 'rgba(255, 255, 255, 0)');       
      
        return {
          labels: labelset,
          datasets: [{
              label: "Data",
              borderColor: borderColor,
              pointBorderColor: pointColor,
              pointBackgroundColor: pointColor,
              pointHoverBackgroundColor: pointColor,
              pointHoverBorderColor: pointColor,
              pointBorderWidth: 5,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 1,
              fillColor: gradient1,
              backgroundColor: gradient1,
              borderWidth: 4,
              data: dataset,
              lineTension: 0
          }]
  
        };
    }

    let donutOptions = {
      legend: {
          position: "bottom"
      },
      scales: {
          yAxes: [{
              ticks: {
                  fontColor: "rgba(0,0,0,0.5)",
                  fontStyle: "bold",
                  beginAtZero: false,
                  maxTicksLimit: 5,
                  padding: 10
              },
              gridLines: {
                  drawTicks: false,
                  display: true,
                  borderDash: [8, 4]
              }
          }],
          xAxes: [{
              gridLines: {
                  zeroLineColor: "transparent",
                  display: false
              },
              ticks: {
                  padding: 20,
                  fontColor: "rgba(0,0,0,0.5)",
                  fontStyle: "bold"
              }
          }]
      }
    };

    this.state = {
      data,
      donutOptions,
      type : type
    };
  }

  render() {
    const { data, donutOptions, type } = this.state;

    // var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    // Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    //   draw: function () {
    //     originalDoughnutDraw.apply(this, arguments);

    //     var chart = this.chart.chart;
    //     var ctx = chart.ctx;
    //     var width = chart.width;
    //     var height = chart.height;

    //     ctx.font = "500 1.7rem Lato";
    //     ctx.textBaseline = "middle";
    //     ctx.fillStyle = "#359bec";

    //     var text = chart.config.data.text,
    //       textX = Math.round((width - ctx.measureText(text).width) / 2),
    //       textY = height / 2;
          
    //     ctx.fillText(text, textX, textY - 5);
    //     ctx.font = "400 0.85rem Lato";
    //     textX = Math.round((width - ctx.measureText(type === 'dc' ? "Remaining" : "Remaining").width) / 2);

    //     ctx.fillStyle = "#103a8d";

    //     ctx.fillText(type === 'dc' ? "Remaining" : "Remaining", textX, textY + 25);
    //   }
    // });

    return (
      <Line
        options={donutOptions}
        data={data}
        width={450}
      />
    );
  }
}

export default LineChart;
