import React, { useEffect, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

const PlanCardStatic = (props) => {

  const [selectedAmount, setSelectedAmount] = useState();

  useEffect(() => {
    if (props.options) {
      setSelectedAmount(Object.keys(props.options)[0]);
    }
  }, [props]);

  const price = useMemo(() => {
    if (!props.options) return '';
    return props.options[selectedAmount]?.price;
  }, [selectedAmount, props]);

  const purchaseLink = useMemo(() => {
    if (!props.options) return '';
    return props.options[selectedAmount]?.purchaseLink;
  }, [selectedAmount, props]);

  const onAmountChange = (e) => {
    setSelectedAmount(e.target.value);
  }

  return (
    <div className="col-md-4 mt-4">
      <div className="price-box">
        <h2 className="text-center fw-bold">
          {props.title}
        </h2>
        <div className="main-price" style={{ fontWeight: 500 }}>
          {price && `$${price.toFixed(2)}`}
          {props.type && <span style={{ fontWeight: 300, fontSize: '0.69rem', textTransform: 'capitalize' }}> / {props.type}</span>}
        </div>
        <div className="price-feature">
          <ul className="bullet mt-4 mb-0">
            {ReactHtmlParser(props.features)}
          </ul>
        </div>
        <div className="plan-select mt-4">
          <select id="inputState" className="form-select" value={selectedAmount} onChange={onAmountChange}>
            {props.options && Object.keys(props.options).map((key) => (<option value={key}>{key}</option>))}
          </select>
        </div>
        <a href={purchaseLink} target="_blank" className="purchase-btn mt-4">Purchase</a>
      </div>
    </div>
  );
}

export default PlanCardStatic;