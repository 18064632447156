import React from "react";
import { Doughnut, Chart } from 'react-chartjs-2';


class HalfDoughnut extends React.Component {
  constructor(props) {
    super(props);
    let colors = ['#12AFFF', '#FFAC50', '#FF4F7A', '#00EAA7']
    let text = props.text || '';
    let type = props.text || 'resi';
    let dataset = props.dataset || [0, 0];
    let gradientColor = props.gradientColor || colors[Math.floor(Math.random() * colors.length)];

    let data = canvas => {
      const ctx = canvas.getContext('2d');
      // var gradient1 = ctx.createLinearGradient(0, 0, 0, 40);
      // gradient1.addColorStop(0.2, gradientColor);
      // gradient1.addColorStop(0.8, 'white');

      // var gradient2 = ctx.createLinearGradient(0, 0, 0, 300);
      // gradient2.addColorStop(0, 'rgba(99, 131,223, 0.2)');
      // gradient2.addColorStop(0.5, 'rgba(130, 243, 200, 0.2)');
      // gradient2.addColorStop(1, 'rgba(102, 98, 229, 0.2)');

      return {
        config: [{
          cutoutPercentage: 0
        }],
        text: text,
        datasets: [
          {
            data: dataset,
            backgroundColor: [
              gradientColor,
              '#f2f3f4'
            ],
            borderWidth: 0
          }
        ]
      };
    }
    if (type === 'dc' || type === 'datacenter') {
      data.labels = [
        'Remaining',
        'Used'
      ]
    } else {
      data.labels = [
        'Used',
        'Remaining'
      ]
    }

    let donutOptions = {
      responsive: true,
      cutoutPercentage: 75,
      rotation: 1 * Math.PI,
      circumference: 1 * Math.PI,
      legend: {
        display: false
      }
    };

    this.state = {
      data,
      donutOptions,
      type: type
    };
  }

  render() {
    const { data, donutOptions, type } = this.state;

    var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function () {
        originalDoughnutDraw.apply(this, arguments);

        // var chart = this.chart.chart;
        // var ctx = chart.ctx;
        // var width = chart.width;
        // var height = chart.height;

        // ctx.font = "500 1.7rem Lato";
        // ctx.textBaseline = "middle";
        // ctx.fillStyle = "#359bec";

        // var text = chart.config.data.text,
        //   textX = Math.round((width - ctx.measureText(text).width) / 2),
        //   textY = height / 2;

        // ctx.fillText(text, textX, textY - 5);
        // ctx.font = "400 0.85rem Lato";
        // textX = Math.round((width - ctx.measureText(type === 'dc' ? "Remaining" : "Remaining").width) / 2);

        // ctx.fillStyle = "#103a8d";

        // ctx.fillText(type === 'dc' ? "Remaining" : "Remaining", textX, textY + 25);
      }
    });

    return (
      <Doughnut
        options={donutOptions}
        data={data}
        width={100}
        height={30}
      />
    );
  }
}

export default HalfDoughnut;
