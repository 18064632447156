import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts';
import {
  PageLoader,
  showToastAlert,
  parseURLParams
} from '../Services/Utility';


const Register = () => {
  const { firebase, setUser } = useContext(FirebaseContext);
  const history = useHistory();
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    tncCheck: false
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let urlString = window.location.href;
    let urlParams = parseURLParams(urlString);
    let currentUrl = urlString.split('?')[0];
    if (urlParams) {
      if (urlParams.status && urlParams.status[0] === 'success') {
        try {
          const token = urlParams.token[0];
          const newSignup = urlParams.signup[0];
          firebase.auth.signInWithCustomToken(token).then(async userInfo => {
            if (newSignup == 'true') {
              // const lm_data = window.lm.getReferralData();
              // firebase.db.ref(`users/${firebase.auth.currentUser.uid}/`).update({ f_data: lm_data });
              firebase.analytic.logEvent('sign_up', { method: 'Discord' });
            }
            window.history.replaceState({}, document.title, currentUrl);
          })
            .catch((error) => {
              console.error(error);
              showToastAlert("error", error.message);
            });
        } catch (error) {
          console.log(error);
          showToastAlert('Error', 'Error while verifying Discord');
        }
      } else if (urlParams.message) {
        window.history.replaceState({}, document.title, currentUrl);
        showToastAlert("error", urlParams.message[0]);
      }
    }
  }, []);

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        if (user) {
          history.push('/dashboard');
          return;
        } else {
          setLoading(false);
        }
      });
    })();
    document.body.classList.add('login-page');
  }, [history, firebase.auth]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('login-page');
    }
  }, []);

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  }

  const termsConditionChecked = (e) => {
    e.persist();
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.checked
    }));
  }

  const googleLogin = (event) => {
    firebase.doGoogleLogin().then(resUser => {
      if (resUser.additionalUserInfo.isNewUser) {
        // const lm_data = window.lm.getReferralData();
        // firebase.db.ref(`users/${firebase.auth.currentUser.uid}/`).update({ f_data: lm_data });
        firebase.analytic.logEvent('sign_up', { method: 'Google' });
        showToastAlert('Success', "Account created successfully.");
      }
      else {
        showToastAlert('Success', "Account loggedin successfully.");
      }
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (values.firstName.trim() === '' || values.lastName.trim() === '' || values.email.trim() === '' || values.password.trim() === '') {
      return showToastAlert('Error', "All fields are required.");
    }
    if (!values.tncCheck) {
      return showToastAlert('Error', "Please accept terms and conditions");
    }

    firebase.doCreateUserWithEmailAndPassword(values.email, values.password).then(resUser => {
      setUser(resUser);
      return resUser.user.updateProfile({
        displayName: values.firstName + ' ' + values.lastName
      });
    }).then(async () => {
      // const lm_data = window.lm.getReferralData();
      // firebase.db.ref(`users/${firebase.auth.currentUser.uid}/`).update({ f_data: lm_data });
      firebase.analytic.logEvent('sign_up', { method: 'Email' });
      showToastAlert('Success', "Account created successfully.");
    }).catch(error => {
      showToastAlert('Error', error.message);
    });
  }

  const loginDiscord = async () => {
    let url = `${window.location.origin}${window.location.pathname}`;
    let state = JSON.stringify({
      'redirect_url': url
    });
    let discordUrl = `https://discordapp.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_URL}/discord/callback&response_type=code&scope=identify%20email%20guilds%20guilds.join&state=${state}`;
    window.location.href = discordUrl;
  }

  return (
    <>
      {loading && <PageLoader />}
      <section>
        <div className="form-part">
          <div className="form-inner">
            <h2 className="mb-3">Sign Up with Stella Proxies</h2>

            <div className='d-flex social mt-5'>
              <div className="google-login">
                <a href="#" onClick={googleLogin}>
                  <img src={require("assets/images/google.png")} alt="" className="pe-2" /> Sign Up with Google
                </a>
              </div>
              <div className="discord-login">
                <a href="#" onClick={loginDiscord}>
                  <img src={require("assets/images/discord1.png")} alt="" className="pe-2" /> Sign Up with Discord
                </a>
              </div>
            </div>

            <div className="hr-line">
              <img src={require("assets/images/hr-line.svg")} alt="" className="w-100" />
            </div>

            <div className="form-field">
              <div className="row mb-3">
                <div className="col">
                  <input type="text" onChange={handleChange} name="firstName" className="form-control" placeholder="First name" aria-label="First name" required />
                </div>
                <div className="col">
                  <input type="text" onChange={handleChange} name="lastName" className="form-control" placeholder="Last name" aria-label="Last name" required />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <input type="email" onChange={handleChange} name="email" className="form-control" placeholder="Email" aria-label="First name" required />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <input type="password" onChange={handleChange} name="password" className="form-control" placeholder="Password" aria-label="First name" required />
                </div>
              </div>
              <div className="mb-5 form-check">
                <input
                  type="checkbox"
                  className="form-check-input w-custom-2"
                  id="tncCheck"
                  name="tncCheck"
                  onChange={termsConditionChecked}
                  required
                  style={{ paddingLeft: '11px', paddingRight: '11px' }}
                />
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <label className="form-check-label" htmlFor="tncCheck" style={{ marginTop: '6px' }}>I agree to the <a target='__blank' href='/tos'>terms and conditions</a> and the <a target='__blank' href='/privacy'>privacy policy</a>.</label>
                </div>
              </div>
              <div className="form-cta-btn">
                <a href="#" className="mb-3" onClick={handleSubmit} >Submit to Join</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Register;