import React, { useEffect } from 'react';

const TOS = () => {

  useEffect(() => {
    // code to run after render goes here
    document.body.classList.add('dash-bg');
    document.body.classList.add('plan-price-bg');
  }, []);

  return (
    <div id="page-content-wrapper" className="extra-p">

      <div className="dash-inner">

        <div className="row mb-4">
          <div className="col-sm-12">
            <h1 className="fw-bold text-center mt-2 text-blue">Terms of Service</h1>
          </div>
        </div>

        <div className="wow animate__animated animate__fadeInDown" data-wow-duration="2s">
          <div className="plan-price-inner" style={{ fontSize: '1rem', letterSpacing: '0.5px', lineHeight: '1.5' }}>
            <h5 className="fw-bold">LAST REVISED: November 29, 2021</h5><br />
            <h6 className="fw-bold">1. Introduction</h6>
            <p className="description">Welcome! You, individually or on behalf of the entity You represent (“You,” “Your”, “Yourself” or “Users”), are entering into an Agreement with Stella Proxies LLC ("Stella", "We", "Our", or "Us"). The terms of this Agreement include these Terms of Service, Our Privacy Notice and other provisions (“Agreement”) that We specify as applicable to Your access and use of: (i) https://stellaproxies.io (“Site”), and/or the acquisition of IP address proxies that we may make available (collectively as applicable “Services”). If You do not agree to this Agreement You must neither access nor use Our Services. In agreeing, You also represent that You have the legal authority to bind Yourself and/or the company You represent, and have disclosed all information to Us as necessary to make the requested Services available to You. Capitalized terms are as defined throughout this Agreement.</p>
            <h6 className="fw-bold">2. Services and Account Requirements</h6>
            <p className="description">‍<span className='fw-bold'>a. <u>Services</u></span>: Services are generally designed to help encrypt Your network’s IP address when accessing and/or using Third-Party Websites that may otherwise block Your access. You must register and create an account for access and use of Our Services (“Account”). If You create an Account and submit information to Us, You represent and warrant that all such personal information is accurate and current. Additionally, You must promptly update Your Account information in the event of any changes. If We have reason to believe that Your Account information is inaccurate, outdated, and/or incomplete, We may suspend or terminate Your account and/or use of Our Services. You agree not to: (i) access or use Services using a false identity or information that is not Your own; and/or (ii) create an Account or use the Services if You have been previously removed from the Services. We reserve the right, in Our sole discretion, to accept or reject Your registration to use Our Services and/or as well as suspend or terminate Your Account for any reason, or no reason at all. Payments are processed by https://stripe.com/payments and are subject to additional terms and conditions, which we strongly encourage You to review.</p>
            <p className="description">‍<span className='fw-bold'>b. <u>Account Security</u></span>: You are entirely responsible for maintaining the confidentiality of Your password and Account. You are entirely responsible for any and all activities that occur under Your Account. You agree to notify Us immediately of any unauthorized use of Your Account or any other breach of security. We will not be liable for any loss, damages, liability, expenses or attorneys’ fees that You may incur as a result of someone else using Your password or Account, either with or without Your knowledge. You will be liable for losses, damages, liability, expenses and attorneys’ fees incurred by Us or a third party due to someone else using Your Account, regardless of whether they are authorized.</p>
            <p className="lead">ANY SUSPECTED FRAUDULENT, ABUSIVE OR ILLEGAL ACTIVITY THAT MAY BE GROUNDS FOR TERMINATION OF YOUR ACCOUNT AND ACCESS OR USE OF SERVICES AND MAY BE REFERRED TO APPROPRIATE LAW ENFORCEMENT AUTHORITIES. THESE REMEDIES ARE IN ADDITION TO ANY OTHER REMEDIES WE MAY HAVE AT LAW OR IN EQUITY.</p>
            <h6 className="fw-bold">3. License and Restrictions</h6>
            <p className="description">‍<span className='fw-bold'>a. <u>License</u></span>: We hereby grant you a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the proxy We make available to You to access Third-Party Websites and as expressly permitted by the Agreement. Except for this limited license, we do not grant you any other rights or license. We reserve all rights not expressly granted herein.</p>
            <p className="description">‍<span className='fw-bold'>b. <u>Restrictions</u></span>: With respect with Services you are prohibited from and agree not to do the following:</p>
            <ol type='i' style={{ marginLeft: '40px' }}>
              <li>You will not use Services for any commercial or non-private use, such as the sale or advertisement of goods or services, and You understand that Services are for personal, non-commercial use only in the manner and for the purposes that We intend</li>
              <li>You will not use Services for the commission or encouragement of any illegal purpose, or in violation of any local, state, national, or international law, including laws governing criminal acts, prohibited or controlled substances, intellectual property and other proprietary rights, data protection and privacy, and import or export control</li>
              <li>You will not impersonate any person or entity, falsely claim an affiliation with any person or entity</li>
              <li>You will not remove, circumvent, disable, damage or otherwise interfere with security-related features of Services</li>
              <li>You will not intentionally interfere with or damage operation of Services</li>
              <li>You will not attempt to gain unauthorized access to Services, or any part of it, other Accounts, computer systems or networks connected to Services, or any part of it, through hacking, password mining or any other means, or interfere or attempt to interfere with the proper working of Services or any activities conducted on Service; and/or</li>
              <li>You will not probe, scan, or test the vulnerability of Services or any system or network; use any robot, sniffer, spider, scraper or other automated means to access Services</li>
            </ol>
            <h6 className="fw-bold">4. User Content and Our Proprietary Rights</h6>
            <p className="description">‍<span className='fw-bold'>a. <u>User Content</u></span>: You hereby grant Us a non-exclusive, fully-paid, royalty-free, world-wide, universal, transferable license to: display, publicly perform, distribute, store, broadcast, transmit, reproduce perform, distribute, store, broadcast, transmit, modify, prepare derivative works and otherwise use and reuse all or any part of any information that You submit in connection with Your use of Services (“User Content”). You hereby represent and warrant that You own all rights to Your Content or, alternatively, that You have the right to give us the license described above. You represent and warrant that User Content does not infringe on the intellectual property rights, privacy rights, publicity rights, or other legal rights of any third-party. You waive and agree never to assert any and all moral rights in and to all of the materials licensed in this Section. We reserve the right to display advertisements in connection with Your User Content. We are not required to host, display, or distribute any of Your User Content and We may refuse to accept or transmit User Content, and may remove or delete all or any portion of User Content from Our Services at any time.</p>
            <p className="description">‍<span className='fw-bold'>b. <u>Our Proprietary Rights</u></span>: The design of Services including the text, scripts, graphics, interactive features and the trademarks, service marks and logos contained therein ("Our Content"), are owned by or licensed to Us and are subject to copyright and other intellectual property rights under United States and foreign laws and international conventions.  You agree not to engage in the misuse, copying and/or redistribution of Our Content contained within Services.</p>
            <h6 className="fw-bold">5. Indemnification and Release</h6>
            <p className="description">‍<span className='fw-bold'>a. <u>Users</u></span>: You shall indemnify, defend and hold (i) Stella and its officers, directors, shareholders and agents harmless for any and all losses, fines, claims, costs, disputes, property damage or property loss, death or bodily injury, demands and liabilities (including reasonable attorneys’ fees) arising out of or incurred due to: Your breach of this Agreement; Your use or misuse of Services; (ii) Your interaction with Third-Party Websites; (iii) Your User Content; Your employees and/or subcontractors; Your violation of applicable law; and (iv) any and all direct or indirect loss, liability, damage, claim, fine, cost or expense, including reasonable attorney’s fees, arising out of or in any way related to Your performance or breach of this Agreement, violation of applicable Law, employees, subcontractors or independent contractors, including, but not limited to, claims for or related to personal injury, property damage, and inability to use Services to access Third-Party Websites (“Claims”).  Stella reserves the right, at Your expense, to assume the exclusive defense and control of any matter for which You are required to indemnify Us and You agree to cooperate with Our defense of all applicable Claims. You agree not to settle any matter without the prior written consent of Stella. Stella will use reasonable efforts to notify You of any such Claim, action or proceeding upon becoming aware of it.</p>
            <p className="description">‍<span className='fw-bold'>b. <u>Stella</u></span>: We will indemnify, defend and hold You harmless from any and all liabilities, losses, damages, suits, penalties, fines, costs or expenses (including, reasonable attorneys’ fees and other applicable expenses) relating to third-party claims that Services infringe upon/on that party’s Intellectual Property Rights (“Infringement Claim”).  Our obligation under this is contingent upon Your prompt written notice of any such claim and reasonable assistance in defending any such Infringement Claim.</p>
            <p className="description">‍<span className='fw-bold'>c. <u>Release</u></span>: To the fullest extent permitted by applicable law, You hereby release and forever discharge Us (and Our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, emotional distress, identity theft, death, and property loss and damage), that has arisen or arises directly or indirectly out of, or relates directly or indirectly to Our Services and this Agreement (including any Third Party Websites). IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”</p>
            <p className="">WE DISCLAIM ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS BY THIRD-PARTY WEBSITES. STELLA NEITHER OWNS NOR CONTROLS THIRD-PARTY WEBSITES AND ARE THEREFORE NOT RESPONSIBLE FOR THIRD-PARTY WEBSITE UPDATES THAT MAY IMPACT THE USE OF SERVICES. WE RESERVE THE RIGHT TO CHANGE SERVICES, AND OTHER ITEMS USED OR CONTAINED IN SERVICES AT ANY TIME WITHOUT PRIOR NOTICE.</p>
            <h6 className="fw-bold">6. Disclaimer of Warranties</h6>
            <p className="">TO THE EXTENT PERMITTED BY APPLICABLE LAW YOU UNDERSTAND AND AGREE THAT SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.  STELLA AND ITS RESPECTIVE SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE AVAILABILITY OF SERVICES (INCLUDING OUR CONTENT), AND THE USER CONTENT YOU OR OTHER USERS SUBMIT. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS.  WE DISCLAIM TO THE FULLEST EXTENT PERMISSIBLE BY LAW, AND YOU WAIVE, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE FUNCTIONS AND FEATURES OF SERVICES ARE NOT WARRANTED TO BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE. STELLA DISCLAIMS ANY RESPONSIBILITY FOR THE DELETION, FAILURE TO STORE, MISDELIVERY, OR UNTIMELY DELIVERY OF ANY USER CONTENT AND/OR
              OUR CONTENT. YOU ASSUME THE ENTIRE RISK OF LOSS OF USER CONTENT AND/OR DAMAGE DUE TO YOUR USE OF SERVICES. CERTAIN STATE, PROVINCIAL, AND NATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
            </p>
            <h6 className="fw-bold">7. Limitation of Liability</h6>
            <p className="">STELLA SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT STELLA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
            <p className="">STELLA SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (I) YOUR USE OF OR RELIANCE ON SERVICES OR YOUR INABILITY TO ACCESS OR USE SERVICES; OR (II) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU THIRD-PARTY WEBSITES. IN NO EVENT SHALL STELLA'S TOTAL LIABILITY TO YOU IN CONNECTION WITH SERVICES AND SHIPMENTS FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED FIVE HUNDRED (US $500.00) U.S. DOLLARS. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.</p>
            <h6 className="fw-bold">8. Third-Party Websites</h6>
            <p className="description">Services may include links to other web sites or services, whether through advertising or otherwise, (“Third-Party Websites”).  We do not endorse any Third-Party Website. Furthermore, We make no express or implied warranties with regard to the information, material, Merchandise or services acquired with the use of Service via Third-Party Websites. We do not control these Third-Party Websites and this Agreement does not apply to companies that Stella does not own nor control. You should always check the terms of use posted on Third-Party Websites. You agree that Stella will not be responsible or liable for any loss or damage of any sort incurred as the result of Your interaction with Third-Party Websites.</p>
            <h6 className="fw-bold">9. Resolving disputes</h6>
            <p className="description">‍<span className='fw-bold'>a. <u>General</u></span>: You and Us agree that any dispute that has arisen or may arise between us relating in any way to Your use of or access to Services, this Agreement, or otherwise relating to Us in any way (collectively, “Dispute Matters”) will be resolved in accordance with the provisions set forth in this Section 10.</p>
            <p className="description">‍<span className='fw-bold'>b. <u>Informal Resolution</u></span>: If You have any dispute with Us, You and We agree that before taking any formal action, You will contact Us at <a href='mailto:admin@stellaproxies.com'>admin@stellaproxies.com</a> to provide a brief, written description of the dispute and Your contact information; and allow sixty (60) days to pass, during which We will attempt to reach an amicable resolution with You.</p>
            <p className="description">‍<span className='fw-bold'>c. <u>Applicable Law</u></span>: You and We agree that United States federal law including the Federal Arbitration Act, and (to the extent not inconsistent with or pre-empted by federal law) the laws of the State of New York, USA, without regard to conflict of laws principles, will govern all Dispute Matters. Such body of law will apply regardless of Your residence or the location of where You use Services.</p>
            <p className="description">‍<span className='fw-bold'>d. <u>Our Arbitration</u></span>: You and Us agree that this Agreement and each of its parts evidence a transaction involving interstate commerce, and the Federal Arbitration Act applies in all cases and governs the interpretation and enforcement of the arbitration rules and arbitration proceedings. Any Disputed Matter must be asserted individually in binding arbitration administered by the American Arbitration Association (“AAA”) in accordance with its Consumer Arbitration Rules (including utilizing desk, phone or video conference proceedings where appropriate and permitted to mitigate costs of travel). You and Us agree that the arbitrator shall not conduct any form of class or collective arbitration nor join or consolidate claims by or for individuals. You and Us agree that the arbitrator, and not any federal, international, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability or formation of this Agreement, including any claim that all or any part of this Agreement is void or voidable or a particular claim is subject to arbitration. You and Us agree that judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</p>
            <p className="description">‍<span className='fw-bold'>e. <u>The Arbitrator’s Award to You or Us</u></span>: You and Us agree that for matters where the relief sought is over $5,000, the arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award. The arbitrator will decide the substance of all claims in accordance with applicable law, including recognized principles of equity, and will honor all claims of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different Users, but is bound by rulings in prior arbitrations involving the same User to the extent required by applicable law. You and Us agree that the arbitrator’s award shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. YOU AND US AGREE THAT THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S), FOR EXAMPLE YOUR CLAIMS. ANY RELIEF AWARDED CANNOT AFFECT OTHER USERS.</p>
            <p className="description">‍<span className='fw-bold'>f. <u>Exceptions</u></span>: There are only two exceptions to this agreement to arbitrate:</p>
            <ol type='i' style={{ marginLeft: '40px' }}>
              <li>if either party reasonably believes that the other party has in any manner violated or threatened to infringe the intellectual property rights of the other party, the party whose rights have been violated may seek injunctive or other appropriate interim relief in any court of competent jurisdiction; or</li>
              <li>if either party reasonably believes that the applicable dispute can be resolved in a small claims court and such court has jurisdiction.</li>
            </ol>
            <p className="description">‍<span className='fw-bold'>g. <u>Who Bears the Costs of Arbitration</u></span>: You and Us agree that payment of all filing, administration, and arbitrator fees will be governed by the AAA’s rules, unless otherwise stated in this agreement to arbitrate. If the value of the relief sought is $5,000 or less, at Your written request, We will reimburse You for the filing, administration, and arbitrator fees associated with the arbitration following the earlier of the arbitrator’s decision or settlement, provided that You make your request no more than thirty (30) days following the earlier of such decision or settlement. In the event the arbitrator determines the claim(s) You assert in the arbitration to be frivolous or without merit, You agree that We are relieved of its obligation to reimburse You for any fees associated with the arbitration.</p>
            <p className="description">‍<span className='fw-bold'>h. <u>Judicial Forum for Legal Disputes</u></span>: In the event that the agreement to arbitrate above is found not to apply to You or to a particular claim or dispute, either as a result of Your decision to opt out of the agreement to arbitrate, as a result of a decision by the arbitrator or a court order, or because You are an international User to which this agreement to arbitrate does not apply, You agree (except as otherwise provided by law) that any claim or dispute that has arisen or may arise between You and Us must be resolved exclusively by a state or federal court located in New York City, New York. You and Us agree to submit to the exclusive personal jurisdiction of the courts located within New York City, New York for the purpose of litigating all such claims or disputes.</p>
            <p className="description">‍<span className='fw-bold'>i. <u>YOU MAY OPT-OUT OF ARBITRATION</u></span>: YOU CAN CHOOSE TO REJECT THE AGREEMENT TO ARBITRATE PROVISION (“OPT-OUT”) BY EMAILING US AN OPT-OUT NOTICE TO ADMIN@STELLAPROXIES.COM (“OPT-OUT NOTICE”) NO LATER THAN THIRTY (30) DAYS OF YOUR ACCEPTANCE OF THIS AGREEMENT.</p>
            <p className="description">‍<span className='fw-bold'>j. <u>Arbitration Opt-Out Procedure</u></span>: In order to opt-out, You must email Your name, address (including street address, city, state, and zip code), email address and a valid and current government issued form of identification to verify Your identity. If You opt out of the agreement to arbitrate, all other parts of this Agreement will continue to apply to You.</p>
            <p className="description">‍<span className='fw-bold'>k. <u>YOU WAIVE CERTAIN RIGHTS</u></span>: BY AGREEING TO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE (I) TO A COURT TRIAL (OTHER THAN SMALL CLAIMS COURT AS PROVIDED ABOVE), (II) TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT, ARBITRATION OR OTHER PROCEEDING FILED AGAINST US AND/OR RELATED THIRD PARTIES, AND (III) TO A TRIAL BY JURY EVEN IF ANY ARBITRATION IS NOT REQUIRED UNDER THIS AGREEMENT.</p>
            <p className="">STATUTE OF LIMITATIONS FOR YOUR CLAIMS. REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE OF THE SITE, SERVICES, OR THIS AGREEMENT MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION ARISES OR IT WILL BE FOREVER BARRED.</p>
            <h6 className="fw-bold">10. Additional Provisions</h6>
            <p className="description">‍<span className='fw-bold'>a. <u>Compliance and Choice of Law</u></span>: Each party will comply with all laws applicable to this Agreement. This Agreement shall be governed by the laws of the State of New York without giving effect to its principles regarding conflicts of law. All disputes shall be resolved exclusively in state or federal court in New York City, New York. You acknowledge that the Services are of United States origin and agree to comply with all export laws and regulations of the United States.</p>
            <p className="description">‍<span className='fw-bold'>b. <u>Force Majeure</u></span>: We will not be liable for any failure or delay in performance to the extent caused by any circumstances beyond Our reasonable control.</p>
            <p className="description">‍<span className='fw-bold'>c. <u>Severability; Headings</u></span>: The unenforceability of any provision of this Agreement will not affect the enforceability of any other provision. If any provision of this Agreement is deemed to conflict with another, We will have the sole right to elect which provision remains in force. Headings are provided for convenience only.</p>
            <p className="description">‍<span className='fw-bold'>d. <u>Non-waiver</u></span>: We reserve all rights under applicable law. Our non-enforcement of any provision of this Agreement or under applicable law will not be construed as Our waiver of any enforcement rights under the same or different circumstances at any time in the future.</p>
            <p className="description">‍<span className='fw-bold'>e. <u>Termination</u></span>: We may suspend or terminate the Services or Your Account at Our discretion without explanation and notice, though We will strive to provide a timely explanation. All provisions of this Agreement which by their nature should survive termination shall survive termination, including ownership, fees, warranty disclaimers, indemnity and limitations of liability.</p>
            <p className="description">‍<span className='fw-bold'>f. <u>Electronic Communications</u></span>: You consent to receive communications from Us electronically, and agree that all terms, conditions, or otherwise, provided to You electronically satisfy any legal requirement that would be satisfied if they were in writing.</p>
            <p className="description">‍<span className='fw-bold'>g. <u>Modifications</u></span>: <b>PLEASE NOTE THAT THIS AGREEMENT IS SUBJECT TO CHANGE BY US IN OUR SOLE DISCRETION AT ANY TIME.</b> When changes are made, We will make a new copy of the Agreement available to You through Services. We will also update the "Last Revised" date at the top of the Agreement. If We make any material changes, and You have created an Account, We may also send an e-mail to You at the last e-mail address You provided to Us. Any changes to the Agreement will be effective immediately. We may require You to provide consent to the updated Agreement in a specified manner before further use of Services is permitted. If You do not agree to any change(s) after receiving a notice of such change(s), please stop all access and use of Services. Otherwise, Your continued access and use Services constitutes Your acceptance of such change(s). </p>
            <p className="description">‍<span className='fw-bold'>h. <u>Entire Agreement</u></span>: This Agreement contains the entire agreement of the parties and supersedes all other agreements and understandings with respect to the matters contained herein.</p>
            <p className="description">‍<span className='fw-bold'>i. <u>California Users and Residents</u></span>: Pursuant to California Civil Code Section 1789.3, questions about pricing, complaints, or inquiries must be addressed to Our agent for notice and sent via certified mail to: 30 N Gould St, STE R, Sheridan WY 8280. California Users are also entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.</p>
            <p className="description">‍<span className='fw-bold'>j. <u>Privacy Notice</u></span>: Our collection, use, and sharing of Your personal information is subject to Our <a href='/privacy'>Privacy Notice</a>.  You understand that through Your access and use of Services You consent to the collection, retention, use, and sharing of this information as set forth in Our <a href='/privacy'>Privacy Notice</a>.</p>
            <p className="description">‍<span className='fw-bold'>k. <u>Support</u></span>: If you have any questions about this Agreement, please contact Us at <a href='mailto:admin@stellaproxies.com'>admin@stellaproxies.com</a></p>
            <br />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TOS;