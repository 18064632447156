import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../contexts';
import { fetchDiscordUser } from '../../Services/Utility';

const Navbar = (props) => {

  const { user, firebase } = useContext(FirebaseContext);
  const [discordData, setDiscordData] = useState({});

  const verifyDiscord = async () => {
    let idToken = await firebase.auth.currentUser.getIdToken(true);
    let url = `${window.location.origin}${window.location.pathname}`;
    let state = JSON.stringify({
      'redirect_url': url,
      idToken: idToken
    });
    let discordUrl = `https://discordapp.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_URL}/discord/callback&response_type=code&scope=identify%20email%20guilds%20guilds.join&state=${state}`;
    window.open(discordUrl, '_blank');
  }

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (user) {
            let idToken = await firebase.auth.currentUser.getIdToken(true);
            const discordId = (await firebase.db.ref(`users/${user.uid}/discordId`).once('value')).val();
            if (discordId && discordId !== '') {
              try {
                let discord_user = await fetchDiscordUser(discordId, idToken);
                setDiscordData(discord_user);
              } catch (error) {
                console.log(error);
              }
            }
          }

          // const ref = firebase.db.ref(`users/${user.uid}/discordId`);
          // ref.on('value', async (snapshot) => {
          //   const discordId = snapshot.val();
          //   if (discordId && discordId !== '') {
          //     try {
          //       let discord_user = await fetchDiscordUser(discordId, idToken);
          //       setDiscordData(discord_user);
          //     } catch (error) {
          //       console.log(error);
          //     }
          //   }
          // });

        } catch (error) {
          console.log(error);
        }
      });
    })();
  }, [firebase.auth, firebase.db]);

  return (
    <div className="top-nav-part top-nav">
      <div>
        <button id="menu-toggle" className="navbar-toggler shadow-none border-0" type="button" data-toggle="collapse"
          data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
          aria-label="Toggle navigation" onClick={() => props.toggleSideMenu()}>
          <svg width="26" height="20" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.462402" y="0.582031" width="31.1333" height="3.63241" rx="1.8162" fill="#359BEC" />
            <rect x="0.462402" y="9.19141" width="31.1333" height="3.63241" rx="1.8162" fill="#359BEC" />
            <rect x="0.462402" y="17.8027" width="31.1333" height="3.63241" rx="1.8162" fill="#359BEC" />
          </svg>
        </button>
      </div>
      <div className="top-nav-part mb-0">
        <div className="nav-icon">
          <a href="/settings">
            <img src={require("assets/images/setting.svg")} alt="" />
          </a>
        </div>
        {/* <div className="nav-icon me-1">
          <a href="#">
            <img src={require("assets/images/notification.png")} alt="" />
          </a>
        </div> */}

        <div className="profile">
          {/* {
            discordData && discordData.id &&
            <img src={discordData.avatar ? discordData.avatar : require("assets/images/Userpic.png")} alt="" />
          } */}
          {
            discordData && discordData.id &&
            <span>{discordData.username ? discordData.username : (user && user.displayName ? user.displayName : 'User')}</span>
          }
          {
            discordData && !discordData.id &&
            <button onClick={verifyDiscord} style={{ backgroundColor: "transparent" }}>
              <img src={require("assets/images/discord.png")} alt="" />
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export default Navbar;