import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'reactstrap';
import { FirebaseContext } from '../../contexts';
import {
  showToastAlert,
  parseURLParams
} from '../../Services/Utility';


const menuClass = 'list-group-item list-group-item-action mt-4';
const menuActiveClas = 'list-group-item list-group-item-action mt-4 active';

const Header = (props) => {

  const { user, firebase } = useContext(FirebaseContext);

  useEffect(() => {
    let urlString = window.location.href;
    let urlParams = parseURLParams(urlString);
    let currentUrl = urlString.split('?')[0];
    window.history.replaceState({}, document.title, currentUrl);
    if (urlParams) {
      if (urlParams.payment && urlParams.payment[0] === 'success' && urlParams.plantype && urlParams.planid && urlParams.subplanid && urlParams.price) {
        const plantype = urlParams.plantype[0];
        const price = urlParams.price[0];
        const planid = urlParams.planid[0];
        const subplanid = urlParams.subplanid[0];
        firebase.analytic.logEvent('purchase', { value: price, plan_type: plantype, plan_id: planid, subplan_id: subplanid });
      }
      if (urlParams.status && urlParams.status[0] === 'success') {
        try {
          showToastAlert('success', 'Discord successfully verified');
        } catch (error) {
          showToastAlert('Error', 'Error while verifying Discord');
        }
      } else if (urlParams.message) {
        showToastAlert('Error', urlParams.message[0]);
      }
    }
  }, []);

  return (
    <>
      {
        user &&
        <div id="sidebar-wrapper" onMouseEnter={() => props.toggleSideMenu(true)} onMouseLeave={() => props.toggleSideMenu(false)}>
          <div className="sidebar-heading text-center mt-3">
            <a href="/">
              <img src={require("assets/images/stella.png")} alt="" style={{ width: '2em', marginLeft: '-12px' }} />
            </a>
          </div>
          <div className="list-group list-group-flush mt-3">
            <a href="/dashboard" className={props.activedSideMenu == 'dashboard' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'}>
              <div>
                <img src={require("assets/images/nav-home.svg")} alt="" className="dash-mene-img" />
              </div>
              <span>Residential Proxies</span>
            </a>

            <a href="/isp" className={props.activedSideMenu == 'isp' ? menuActiveClas : menuClass}>
              <div>
                <img src={require("assets/images/nav-earth.svg")} alt="" className="dash-mene-img" />
              </div>
              <span>ISP</span>
            </a>

            <a href="/nike" className={props.activedSideMenu == 'nike' ? menuActiveClas : menuClass}>
              <div>
                <img src={require("assets/images/nav-earth.svg")} alt="" className="dash-mene-img" />
              </div>
              <span>Nike</span>
            </a>

            <a href="/orders" className={props.activedSideMenu == 'orders' ? menuActiveClas : menuClass}>
              <div>
                <img src={require("assets/images/nav-order.svg")} alt="" className="dash-mene-img" />
              </div>
              <span>Order History</span>
            </a>

            <a href="/pricing" className={props.activedSideMenu == 'pricing' ? menuActiveClas : menuClass}>
              <div>
                <img src={require("assets/images/nav-purchase.svg")} alt="" className="dash-mene-img" />
              </div>
              <span>Pricing</span>
            </a>

            <a href="/contactus" className={props.activedSideMenu == 'contactus' ? menuActiveClas : menuClass}>
              <div>
                <img src={require("assets/images/nav-contact.svg")} alt="" className="dash-mene-img" />
              </div>
              <span>Contact Us</span>
            </a>

            <Button onClick={e => { firebase.clearState = true; firebase.doSignOut() }} className="list-group-item list-group-item-action mt-4">
              <div>
                <img src={require("assets/images/nav-logout.svg")} alt="" className="dash-mene-img" />
              </div>
              <span>Logout</span>
            </Button>

          </div>
        </div>
      }
      {
        !user && props.toggleSideMenu(false)
      }
    </>
  )
}

export default Header;