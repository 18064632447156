import React, { useContext, useState, useEffect, Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import * as request from 'request';
import { FirebaseContext } from 'contexts/Firebase';
import { useHistory } from 'react-router-dom';
import Navbar from "components/Layout/Navbar";
import {
  CheckoutModal,
  showToastAlert
} from 'Services/Utility';

const Pricing = (props) => {
  const { user, firebase } = useContext(FirebaseContext);
  const history = useHistory();
  const [hasDiscordId, setHasDiscordId] = useState(false);
  const [resiPlans, setResiPlans] = useState({});
  const [ispPlans, setIspPlans] = useState({});
  const [bulkPlans, setBulkPlans] = useState([]);
  const [nikePlans, setNikePlans] = useState({});
  const [activeTab, setActiveTab] = useState();
  const [pricingCode, setPricingCode] = useState('');
  const [checkoutModals, setCheckoutModals] = useState([]);
  const [ispDcStocksLeft, setIspDcStocksLeft] = useState({});
  const [nikeStocksLeft, setNikeStocksLeft] = useState(0);

  const openCheckoutModal = async (planType, planID, subPlanID) => {
    try {
      setCheckoutModals([]);

      if (!user) {
        showToastAlert('Error', 'Log in required to purchase a plan');
        return;
      }

      if (!hasDiscordId) {
        return showToastAlert('Error', "Please verify your discord account");
      }

      let showModal = true;
      let plan;
      if (planType == "residential") {
        plan = resiPlans;
      } else if (planType == "isp") {
        plan = ispPlans;
      } else if (planType == "nike") {
        plan = nikePlans;
      } else {
        return;
      }

      let planData = plan[planID];
      if (!planData) { return; }

      setCheckoutModals([
        <CheckoutModal
          showModal={showModal}
          checkoutPlanID={planID}
          checkoutsubPlanID={subPlanID}
          checkoutPlanType={planType}
          planData={planData}
          ispPlans={ispPlans}
          key={Math.random()}
          firebase={firebase}
        />
      ]);
    } catch (error) {
      showToastAlert('Error', error.message);
    }
  }

  const getIspDcStocksLeft = () => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/isp/inStockDC`,
        headers: {
          'Content-Type': 'application/json'
        }
      };
      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          return resolve({});
        } else {
          if (resData.data) return resolve(resData.data);
          return resolve({});
        }
      });
    });
  }

  const getNikeStocksLeft = () => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/nike/allocationleft`,
        headers: { 'Content-Type': 'application/json' }
      };
      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          return resolve(0);
        } else {
          if (resData.inStock) return resolve(resData.inStock);
          return resolve(0);
        }
      });
    });
  }

  useEffect(() => {
    // code to run after render goes here
    document.body.classList.add('dash-bg');
    document.body.classList.add('plan-price-bg');
    props.setOpenedSideMenu('pricing');

    if (props && props.location.hash != '') {
      const hash = props.location.hash.slice(1);
      if (hash === 'residential') {
        setActiveTab(1);
      }
      else if (hash === 'isp') {
        setActiveTab(2);
      }
      else if (hash === 'bulk' || hash === 'bulkProxies') {
        setActiveTab(4);
      }
      else if (hash === 'nike') {
        setActiveTab(5);
      }
      else {
        setActiveTab(1);
      }
    }
    else {
      setActiveTab(1);
    }
  }, []);

  const filterBulkPlans = (plans, planType) => {
    const bulkPlans = Object.keys(plans).filter(key => plans[key].bulk).map(key => {
      let plan = { ...plans[key] };
      plan['planType'] = planType;
      plan['planId'] = key;
      plan['planActive'] = plans.active;
      plan['categories'] = plans.categories;
      return plan;
    })
    return bulkPlans;
  }

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (!user) {
            history.push('/', { 'from': 'pricing', 'hash': props.location.hash });
            return;
          }

          // const [ispDcLeft, nikeLeft] = await Promise.all([getIspDcStocksLeft(), getNikeStocksLeft()]);
          // setIspDcStocksLeft(ispDcLeft);
          // setNikeStocksLeft(nikeLeft);

          const pricingcode = (await firebase.db.ref(`pricing_code/description`).once('value')).val();
          setPricingCode(pricingcode || '');

          firebase.db.ref('plans/').on('value', async (snapshot) => {
            const dbPlans = snapshot.val();

            setResiPlans(dbPlans.residential);
            setIspPlans(dbPlans.isp);
            setNikePlans(dbPlans.nike);

            let bulkPlans = filterBulkPlans(dbPlans.residential, 'residential');
            bulkPlans.push.apply(bulkPlans, filterBulkPlans(dbPlans.isp, 'isp'));
            setBulkPlans(bulkPlans);
          });

          const discordId = (await firebase.db.ref(`users/${user.uid}/discordId`).once('value')).val();
          if (discordId && discordId !== '') {
            setHasDiscordId(true);
          }

        } catch (error) {
          console.log(error);
          showToastAlert('Error', error.message);
        }
      });
    })();
  }, [history, firebase.auth, firebase.db]);

  return (
    <>
      {
        user &&
        <div id="page-content-wrapper" className="extra-p">

          <Navbar toggleSideMenu={props.toggleSideMenu} />

          <div className="dash-inner">
            {checkoutModals.map(checkoutModal => (
              checkoutModal
            ))}
            <div className="row">
              <div className="col-sm-12">
                <h1 className="fw-bold text-center mt-2 text-blue">Pricing Plans</h1>
                <p className="text-center text-blue px-3">Choose the features and functionality according to your requirements.</p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 plan-nav">
                <ul className="nav nav-pills mb-3 justify-content-center mt-3" id="pills-tab-pricing" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link w-100 ${activeTab == 1 ? 'active' : ''}`} onClick={setActiveTab.bind(this, 1)} key="pills-home-tab1" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Private Residential Plans</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link w-100 ${activeTab == 5 ? 'active' : ''}`} onClick={setActiveTab.bind(this, 5)} key="pills-home-tab5" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Accounts</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link w-100 ${activeTab == 2 ? 'active' : ''}`} onClick={setActiveTab.bind(this, 2)} key="pills-home-tab2" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">ISP</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link w-100 ${activeTab == 4 ? 'active' : ''}`} onClick={setActiveTab.bind(this, 4)} key="pills-home-tab4" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Bulk</button>
                  </li>
                </ul>
                <div className="tab-content container pt-4" id="pills-tabContent">
                  <div className={`tab-pane fade ${activeTab == 1 ? 'show active' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row four-in-row justify-content-center">
                      {resiPlans && Object.keys(resiPlans).filter(x => x !== 'active' && x !== 'categories' && !resiPlans[x].bulk && !resiPlans[x].hide && !resiPlans[x].onlybot && resiPlans[x].topup).map(planID => {
                        let plan = resiPlans[planID];
                        return <GetCard openCheckoutModal={openCheckoutModal} planType='residential' planData={plan} isActive={resiPlans.active} categories={resiPlans.categories} planID={planID} pricingCode={pricingCode} ></GetCard>;
                      })
                      }
                    </div>
                  </div>
                  <div className={`tab-pane fade ${activeTab == 2 ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="row four-in-row justify-content-center">
                      {ispPlans && Object.keys(ispPlans).filter(x => x !== 'active' && x !== 'categories' && !ispPlans[x].bulk && !ispPlans[x].hide && !ispPlans[x].onlybot).sort((a, b) => (ispPlans[a].order || 0) - (ispPlans[b].order || 0)).map(planID => {
                        let plan = ispPlans[planID];
                        if (plan.bulk || plan.hide || plan.onlybot) return null;
                        return <GetCard openCheckoutModal={openCheckoutModal} planType='isp' planData={plan} isActive={ispPlans.active} categories={ispPlans.categories} planID={planID} pricingCode={pricingCode} stocksLeft={ispDcStocksLeft}></GetCard>;
                      })
                      }
                    </div>
                  </div>
                  <div className={`tab-pane fade ${activeTab == 4 ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="row four-in-row justify-content-center">
                      {bulkPlans.map(plan => {
                        if (plan.hide || plan.onlybot) return null;
                        return <GetCard openCheckoutModal={openCheckoutModal} planType={plan.planType} planData={plan} isActive={plan.planActive} categories={plan.categories} planID={plan.planId} pricingCode={''} stocksLeft={ispDcStocksLeft}></GetCard>;
                      })
                      }
                    </div>
                  </div>
                  <div className={`tab-pane fade ${activeTab == 5 ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="row four-in-row justify-content-center">
                      {nikePlans && Object.keys(nikePlans).filter(x => x !== 'active' && x !== 'categories' && !nikePlans[x].bulk && !nikePlans[x].hide && !nikePlans[x].onlybot).map(planID => {
                        let plan = nikePlans[planID];
                        return <GetCard openCheckoutModal={openCheckoutModal} planType='nike' planData={plan} isActive={nikePlans.active} planID={planID} pricingCode={''} stocksLeft={ispDcStocksLeft} nikeStocksLeft={nikeStocksLeft} ispPlans={ispPlans}></GetCard>;
                      })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

class GetCard extends Component {

  constructor(props) {
    super(props);
    let stateJson = {
      planType: props.planType,
      planData: props.planData,
      ispPlans: props.ispPlans,
      isActive: props.isActive,
      categories: props.categories || {},
      selectedCategory: props.selectedCategory,
      planID: props.planID,
      subPlanID: '',
      planName: props.planData.name,
      features: props.planData.features,
      pricingCode: props.pricingCode,
      hide: props.planData.hide,
      stocksLeft: props.stocksLeft || {},
      nikeStocksLeft: props.nikeStocksLeft || 0,
      selectedQty: '',
      price: 0
    };

    if (stateJson.planType === 'nike') {
      if (!stateJson.planData.quantity) {
        stateJson['hide'] = true;
      }
      else {
        const firstQty = Object.keys(stateJson.planData.quantity).map(x => parseInt(x)).sort((a, b) => a - b)[0];
        const firstQtyData = stateJson.planData.quantity[firstQty];
        stateJson['selectedQty'] = firstQty;
        stateJson['subPlanID'] = firstQty;
        stateJson['price'] = firstQtyData.price;
        stateJson['isActive'] = stateJson.isActive && stateJson.planData.active && stateJson.nikeStocksLeft > 0 && stateJson.nikeStocksLeft >= firstQty;

        if (stateJson.planData.isCombo && firstQtyData.isp) {
          const ispPlanData = stateJson.ispPlans[firstQtyData.isp.planId];
          const ispSubplanData = ispPlanData.subPlans[firstQtyData.isp.subPlanId];
          stateJson['price'] = Number(firstQtyData.price) + Number(ispSubplanData.price);
          stateJson['isActive'] = stateJson.isActive && ispPlanData.active && ispSubplanData.active && stateJson.stocksLeft[ispPlanData['servername']] >= ispSubplanData.numberOfProxies;
        }
      }
    }
    else {
      let subPlans = {}, categories = {};
      for (const subplanId in stateJson.planData.subPlans) {
        const subplan = stateJson.planData.subPlans[subplanId];
        if (!subplan.hide) {
          subPlans[subplanId] = subplan;
          categories[subplan.category] = stateJson.categories[subplan.category];
        }
      }
      stateJson.planData.subPlans = subPlans;
      stateJson.categories = categories;
      const subPlanKeys = Object.keys(subPlans);
      if (subPlanKeys.length) {
        let subplanId = '';
        if (stateJson.planType === 'residential') {
          subplanId = subPlanKeys.sort((a, b) => subPlans[a].dataLimitMB - subPlans[b].dataLimitMB)[0];
        }
        else {
          subplanId = subPlanKeys.sort((a, b) => subPlans[a].numberOfProxies - subPlans[b].numberOfProxies)[0];
        }

        const subplan = subPlans[subplanId];
        const price = subplan.price;
        let divide = 1;
        if (stateJson.planType === 'residential') {
          divide = parseInt(parseInt(subplan.dataLimitMB) / 1000);
          stateJson['isActive'] = stateJson.isActive && stateJson.planData.active && subplan.active;
        } else {
          divide = parseInt(subplan.numberOfProxies);
          stateJson['isActive'] = stateJson.isActive && stateJson.planData.active && subplan.active && (stateJson.planData['servername'] === "null" || stateJson.stocksLeft[stateJson.planData['servername']] >= subplan.numberOfProxies);
        }
        let perprice = price / divide;
        perprice = Number.isInteger(perprice) ? perprice : parseFloat(perprice).toFixed(1);
        stateJson['perPrice'] = perprice;
        stateJson['subPlanID'] = subplanId;
        stateJson['price'] = price;
        stateJson['selectedCategory'] = subplan.category;
      }
      else {
        stateJson['hide'] = true;
      }
    }

    this.state = stateJson;
    this.onSubPlanChange = this.onSubPlanChange.bind(this);
    this.onCatChange = this.onCatChange.bind(this);
    this.purchaseInit = this.purchaseInit.bind(this);
  }

  onSubPlanChange(event) {
    let subid = event.target.value;
    let divide = 1, isactive = false;

    if (this.state.planType === 'nike') {
      isactive = this.props.isActive && this.state.planData.active && this.state.nikeStocksLeft > 0 && this.state.nikeStocksLeft >= parseInt(subid);
      const nikeQtyData = this.state.planData.quantity[subid];
      let price = nikeQtyData.price;
      if (this.state.planData.isCombo && nikeQtyData.isp) {
        const ispPlanData = this.state.ispPlans[nikeQtyData.isp.planId];
        const ispSubplanData = ispPlanData.subPlans[nikeQtyData.isp.subPlanId];
        price = Number(nikeQtyData.price) + Number(ispSubplanData.price);
        isactive = isactive && ispPlanData.active && ispSubplanData.active && this.state.stocksLeft[ispPlanData['servername']] >= ispSubplanData.numberOfProxies;
      }
      this.setState(state => ({
        price: price,
        selectedQty: subid,
        subPlanID: subid,
        isActive: isactive
      }));
    }
    else {
      let subplan = this.state.planData.subPlans[subid];
      const price = subplan.price;

      if (this.state.planType === 'residential') {
        divide = parseInt(parseInt(subplan.dataLimitMB) / 1000);
        isactive = this.props.isActive && this.state.planData.active && subplan.active;
      } else {
        divide = parseInt(subplan.numberOfProxies);
        isactive = this.props.isActive && this.state.planData.active && subplan.active && (this.state.planData['servername'] === "null" || this.state.stocksLeft[this.state.planData['servername']] >= subplan.numberOfProxies);
      }

      let perprice = price / divide;
      perprice = Number.isInteger(perprice) ? perprice : parseFloat(perprice).toFixed(1);

      this.setState(state => ({
        price: price,
        perPrice: perprice,
        subPlanID: subid,
        isActive: isactive
      }));
    }
  }

  onCatChange(catId) {
    this.setState(state => ({
      selectedCategory: catId
    }));
  }

  purchaseInit() {
    this.props.openCheckoutModal(this.state.planType, this.state.planID, this.state.subPlanID);
  }

  render() {
    const { hide } = this.state;
    const pricetag = this.state.planType === 'residential' ? 'GB' : 'Proxy';
    if (hide) {
      return '';
    }
    else {
      return (
        <div className="col-sm-6 col-md-4 col-xl-3">
          <div className="plan-price-inner h-100">
            <h2 className="text-blue">{this.state.planName}</h2>
            {
              this.state.planType === 'nike' &&
              <h1 className="display-5 fw-bold text-blue">${this.state.price}</h1>
            }
            {
              this.state.planType !== 'nike' &&
              <h1 className="display-5 fw-bold text-blue">${this.state.perPrice} <small style={{ fontWeight: '400', 'fontSize': '1.1rem' }}>per {pricetag}</small></h1>
            }
            <div className="price-box-disc">
              <ul className="bullet p-0">
                {ReactHtmlParser(this.state.features)}
              </ul>

              <p className="fw-bold text-blue mt-4">{this.state.pricingCode}</p>
              {
                this.state.planType != 'residential' &&
                <ul className="nav nav-pills mb-3 justify-content-center mt-4 small-navs" id="pills-tab2" role="tablist">
                  {this.state.planType != 'residential' && this.state.planType != 'nike' && Object.keys(this.state.categories).map((catId, index) => {
                    return <li key={index} className="nav-item" role="presentation">
                      <button onClick={(e) => this.onCatChange(catId)} className={catId === this.state.selectedCategory ? "active nav-link" : "nav-link"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home2" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{this.state.categories[catId].name}</button>
                    </li>;
                  })}
                </ul>
              }

              <div className="form-field">
                <div className="row mb-3">
                  <div className="col mt-4">
                    <select id="inputState" className="form-select" value={this.state.subPlanID} onChange={this.onSubPlanChange}>
                      {
                        this.state.planType === 'nike' && Object.keys(this.state.planData.quantity).map((qty) => {
                          const qtyData = this.state.planData.quantity[qty];
                          if (this.state.planData.isCombo && qtyData.isp) {
                            const numberOfProxies = this.state.ispPlans[qtyData.isp.planId].subPlans[qtyData.isp.subPlanId].numberOfProxies;
                            return <option value={qty}>{qty} Accounts + {numberOfProxies} Proxies</option>
                          }
                          else {
                            return <option value={qty}>{qty} Accounts</option>
                          }
                        })
                      }
                      {
                        this.state.planType === 'residential' && Object.keys(this.state.planData.subPlans).sort((a, b) => this.state.planData.subPlans[a].dataLimitMB - this.state.planData.subPlans[b].dataLimitMB).map(subId => {
                          let subplan = this.state.planData.subPlans[subId];
                          if (subplan.hide) { return; }
                          return <option value={subId}>{`${parseInt(subplan.dataLimitMB) / 1000} GB`}</option>
                        })
                      }
                      {
                        this.state.planType !== 'nike' && this.state.planType !== 'residential' && Object.keys(this.state.planData.subPlans).sort((a, b) => this.state.planData.subPlans[a].numberOfProxies - this.state.planData.subPlans[b].numberOfProxies).map(subId => {
                          let subplan = this.state.planData.subPlans[subId];
                          if (subplan.hide) { return; }
                          if (subplan.category == this.state.selectedCategory) {
                            return <option value={subId}>{subplan.numberOfProxies} Proxies</option>
                          }
                        })
                      }
                    </select>
                  </div>

                  <div className="col-sm-12 text-center">
                    <div className="form-cta-btn d-flex justify-content-center mt-2">
                      {this.state.isActive ? <a href="#" className="px-5" onClick={this.purchaseInit}>Purchase</a> :
                        <a href="#" className="px-5 bg-danger" style={{ cursor: 'not-allowed' }}>Sold Out</a>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

}

export default Pricing;