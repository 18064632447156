import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as request from 'request';
import { showToastAlert } from './Utility';
import $ from 'jquery';

const INITIAL_STATE = {
    showModal: false
};

class ChangePasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false
        };
        this.closeModal = this.closeModal.bind(this);
        console.log(this.state);
    }

    closeModal = () => {
        this.setState({
            ...INITIAL_STATE
        });
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    clearPassword = async (event) => {
        $('#newPwd').val('');
        $('#repeatPwd').val('');
    }

    savePassword = async (event) => {
        const newPwd = $('#newPwd').val().trim();
        const repeatPwd = $('#repeatPwd').val().trim();

        if (!newPwd || !repeatPwd) {
            return;
        }
        if (newPwd !== repeatPwd) {
            showToastAlert('Error', 'New password and Repeat password should be match');
        }
        else {
            this.props.firebase.auth.currentUser.updatePassword(newPwd).then(() => {
                showToastAlert('Success', 'Password changed');
                this.closeModal();
            }).catch((error) => {
                showToastAlert('Error', error.message);
            });;
        }
    }

    render() {
        const { showModal } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                backdrop="static"
                size="md"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/head-profile.svg")} alt="" /> <h4 className="mb-0">Change password</h4>
                    </div>
                    <p>Choose at least 8 characters. Don’t use a password from another site, or something too obvious like your pet’s name.</p>

                    <div className="form-field">
                        <div className="form-group row mb-3">
                            <label htmlFor="newPwd" className="col-sm-4 col-form-label">New Password</label>
                            <div className="col-sm-8">
                                <input type="password" className="form-control" id="newPwd" />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <label htmlFor="repeatPwd" className="col-sm-4 col-form-label">Repeat New Password</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="repeatPwd" />
                            </div>
                        </div>
                        <div className="row up-z">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.clearPassword}>Clear</a>
                                </div>
                                <div className="form-cta-btn d-inline-block mt-4">
                                    <a href="#" className="mb-3 px-5 text-light" onClick={this.savePassword}>Save</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(ChangePasswordModal);
