import React, { Component, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import * as request from 'request';
import { showToastAlert } from './Utility';

var Stripe;
(async () => {
  Stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
})();
const API_SERVER_URL = process.env.REACT_APP_SERVER_URL;


const INITIAL_STATE = {
  showModal: false
};

class CheckoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      checkoutPlanID: props.checkoutPlanID || '',
      checkoutsubPlanID: props.checkoutsubPlanID || '',
      updatePlanRefID: props.updatePlanRefID || '',
      checkoutPlanType: props.checkoutPlanType || '',
      checkoutPlanPrice: 0,
      planPrice: 'Plan Price $0',
      couponCode: '',
      couponDiscount: 0,
      planData: props.planData || {},
      isSubscription: (props.checkoutPlanType === 'isp' || props.checkoutPlanType === 'dc' || props.checkoutPlanType === 'datacenter' || props.checkoutPlanType === 'server') && !props.updatePlanRefID ? true : false,
      tcChecked: false,
      isFlipdUser: false,
      flipdChecking: false,
      finalPrice: 0,
      totalDiv: true,
      discountDiv: false,
      hideCheckCoupon: props.hideCheckCoupon ? true : false,
      ispPlans: props.ispPlans || {},
      ispSubplanPrice: 0
    };
    if (this.state.planData.daily || this.state.planData.weekly) {
      this.state.isSubscription = false;
    }

    if (this.state.checkoutPlanType === 'server') {
      this.state.checkoutPlanPrice = this.state.planData.price;
    }
    else {
      const subKey = this.state.checkoutPlanType === 'nike' ? 'quantity' : 'subPlans';
      const qtyData = this.state.planData[subKey][this.state.checkoutsubPlanID];
      this.state.checkoutPlanPrice = qtyData.price;
      if (this.state.checkoutPlanType === 'nike' && this.state.planData.isCombo && qtyData.isp) {
        this.state.hideCheckCoupon = true;
        this.state.ispSubplanPrice = this.state.ispPlans[qtyData.isp.planId].subPlans[qtyData.isp.subPlanId].price;
        this.state.checkoutPlanPrice = Number(qtyData.price) + Number(this.state.ispSubplanPrice);
      }
    }

    this.state.planPrice = 'Plan Price $' + this.state.checkoutPlanPrice;
    this.state.finalPrice = this.state.checkoutPlanPrice;
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (!this.state.hideCheckCoupon) {
      this.state.flipdChecking = true;
      this.checkFlipdDiscount();
    }
  }

  closeModal = () => {
    this.setState({
      ...INITIAL_STATE
    });
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  applyCouponCode = async (event) => {
    let $current = event.target;
    let couponCode = this.state.couponCode.trim();
    let planType = this.state.checkoutPlanType;
    let planID = this.state.checkoutPlanID;
    let subPlanID = this.state.checkoutsubPlanID;
    let planPrice = this.state.checkoutPlanPrice;

    if (couponCode && planType && planID && subPlanID) {
      $current.disabled = true;
      $current.innerHTML = `Checking`;
      let idToken = await this.props.firebase.auth.currentUser.getIdToken(true);

      let options = {
        url: `${API_SERVER_URL}/getCouponCodeDiscount`,
        method: 'POST',
        headers: {
          'Authorization': idToken
        },
        form: {
          couponCode,
          planType,
          planID,
          subPlanID
        }
      };

      return request(options, (error, response, body) => {
        $current.disabled = false;
        $current.innerHTML = '';
        $current.innerText = 'Check';
        let resData = body && JSON.parse(body);

        const payBtn = document.getElementById('checkoutPayBtn');

        if (error || response.statusCode !== 200) {
          if (payBtn) {
            payBtn.innerText = `Pay $${planPrice}`;
          }
          else {
            showToastAlert('Info', 'Please contact to administrator.');
          }
          this.setState({ couponCode: '', couponDiscount: 0, totalDiv: true, discountDiv: false, finalPrice: 0 });
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while getting coupon code info!';
          showToastAlert('Error', errorMessage);
        } else {
          if (resData.status === 'success') {
            let discountAmount = parseFloat(resData['discountAmount']);
            let paymentAmount = parseFloat(parseFloat(planPrice - discountAmount).toFixed(2));
            this.setState({ couponDiscount: discountAmount, totalDiv: false, discountDiv: true, finalPrice: paymentAmount });
            showToastAlert('Success', 'Your coupon code is sucessfully applied.');
            if (payBtn) {
              payBtn.innerText = `Pay $${paymentAmount.toFixed(2)}`;
            }
            else {
              showToastAlert('Info', 'Please contact to administrator..');
            }
          }
        }
      });
    }
  }

  doPlanCheckout = async (event) => {
    let $current = event.target;
    if (!this.state.tcChecked) return showToastAlert('Error', 'Please accept terms and conditions');
    try {
      $current.disabled = true;
      $current.innerHTML = `<div class="spinner-grow text-light" role="status"></div> Processing...`;

      let idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
      let planID = this.state.checkoutPlanID;
      let planType = this.state.checkoutPlanType;
      let subPlanID = this.state.checkoutsubPlanID;
      let updatePlanRefID = this.state.updatePlanRefID;
      let couponCode = this.state.couponCode;
      let planPrice = this.state.checkoutPlanPrice;
      let isSubscription = this.state.isSubscription;

      let checkoutData = {
        planID,
        subPlanID,
        planType,
        isSubscription
      };
      if (updatePlanRefID) {
        checkoutData['updatePlanRefID'] = updatePlanRefID;
      }
      if (couponCode) {
        checkoutData['couponCode'] = couponCode;
      }

      let options = {
        url: `${API_SERVER_URL}/createSession`,
        method: 'POST',
        headers: { 'Authorization': idToken },
        form: checkoutData
      };

      return request(options, (error, response, body) => {
        $current.disabled = false;
        let resData = body && JSON.parse(body);

        if (error || response.statusCode !== 200) {
          $current.innerHTML = '';
          $current.innerText = `Pay $${planPrice}`;
          this.setState({ couponCode: '', couponDiscount: 0, totalDiv: true, discountDiv: false, finalPrice: 0 });
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred during plan checkout !';
          showToastAlert('Error', errorMessage);
        } else {
          if (resData.id) {
            Stripe.redirectToCheckout({ sessionId: resData.id }).then((result) => {
              if ('error' in result) {
                showToastAlert('Error', result['error']['message']);
              }
            });
          } else if (resData.status === 'success' && resData.redirect_url) {
            this.props.history.push('/success');
          } else {
            showToastAlert('Error', 'Something went wrong with your payment, Please try again later !');
          }
        }
      });
    } catch (error) {
      $current.disabled = false;
      $current.innerHTML = '';
      $current.innerText = `Pay $${this.state.checkoutPlanPrice}`;
      this.setState({ couponCode: '', couponDiscount: 0, totalDiv: true, discountDiv: false, finalPrice: 0 });
      showToastAlert('Error', error.message || 'A server error occurred during plan checkout !');
    }
  }

  checkFlipdDiscount = async () => {
    try {
      let idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
      let planType = this.state.checkoutPlanType;
      let planId = this.state.checkoutPlanID;

      let options = {
        url: `${API_SERVER_URL}/checkFlipdUser`,
        method: 'POST',
        headers: { 'Authorization': idToken },
        form: { planType, planId }
      };

      request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        const payBtn = document.getElementById('checkoutPayBtn');
        if (resData && resData.discount) {
          const couponCode = resData.couponCode;
          const discountOff = resData.discount;
          const codetype = resData.codetype;
          let discountCost = discountOff;
          let planPrice = this.state.checkoutPlanPrice;

          if (codetype === 'amount_off') {
            planPrice = parseFloat(parseFloat(planPrice - discountOff));
          }
          else if (codetype === 'percent_off') {
            discountCost = parseFloat(planPrice) * (parseFloat(discountOff) / 100);
            planPrice = parseFloat(planPrice - discountCost);
          }
          if (payBtn) {
            payBtn.innerText = `Pay $${planPrice.toFixed(2)}`;
          }
          else {
            showToastAlert('Info', 'Please contact to administrator');
          }
          this.setState({ couponCode: couponCode, couponDiscount: discountCost, isFlipdUser: true, flipdChecking: false, finalPrice: planPrice, totalDiv: false, discountDiv: true });
        }
        else {
          this.setState({ couponCode: '', couponDiscount: 0, flipdChecking: false, totalDiv: true, discountDiv: false, finalPrice: 0 });
          if (payBtn) {
            payBtn.innerText = `Pay $${this.state.checkoutPlanPrice}`;
          }
          else {
            showToastAlert('Info', 'Please contact to administrator!');
          }
        }
      });
    } catch (error) {
      console.log(error);
      const payBtn = document.getElementById('checkoutPayBtn');
      if (payBtn) {
        payBtn.innerText = `Pay $${this.state.checkoutPlanPrice}`;
      } else {
        showToastAlert('Info', 'Please contact to administrator!!');
      }
      this.setState({ couponCode: '', couponDiscount: 0, flipdChecking: false, totalDiv: true, discountDiv: false, finalPrice: 0 });
    }
  }

  subscription_enable = (e) => {
    this.setState({
      isSubscription: e.target.checked
    });
  }

  termsConditionChecked = (e) => {
    this.setState({
      tcChecked: e.target.checked
    });
  }

  render() {
    const { showModal, checkoutPlanID, checkoutsubPlanID, updatePlanRefID, checkoutPlanType, checkoutPlanPrice, couponCode, planPrice, couponDiscount, flipdChecking, isFlipdUser, isSubscription, totalDiv, discountDiv, finalPrice, hideCheckCoupon, ispSubplanPrice } = this.state;
    const isCheckoutInvalid = checkoutPlanID === '' || checkoutPlanType === '';
    const isCouponInvalid = couponCode === '';

    let nikeIspSubPlanData = null;
    if (checkoutPlanType == 'nike' && this.state.planData.isCombo && this.state.planData['quantity'][checkoutsubPlanID].isp) {
      nikeIspSubPlanData = this.state.ispPlans[this.state.planData['quantity'][checkoutsubPlanID].isp.planId].subPlans[this.state.planData['quantity'][checkoutsubPlanID].isp.subPlanId];
    }

    return (
      <Modal
        isOpen={showModal}
        toggle={this.closeModal}
        onClosed={this.closeModal}
        className="whiteCheckout"
        backdrop="static"
        size="md"
        zIndex={9999}
        centered
      >
        <div className="modal-body liner-g payout-modal">
          <div className="pop-bg-img">
            <img src={require("assets/images/form-bg.png")} alt="" className="w-50" />
          </div>
          <button
            type="button"
            className="btn-close pop-close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={this.closeModal}
          />
          <div className="dash-head d-block">
            <h3 className="mb-0 text-blue">Proceed with Payment</h3>
          </div>

          <div className="process-details">
            <div className="pd-1">
              <h4 className="text-blue">{this.state.planData && this.state.planData.description} </h4>
              <p className="text-blue">
                {(checkoutPlanType == 'datacenter' || checkoutPlanType == 'isp') &&
                  "Quantity - " + (Array.isArray(this.state.planData.subPlans) ? this.state.planData.subPlans.find(subplan => subplan.subplanId === checkoutsubPlanID).numberOfProxies : this.state.planData.subPlans[checkoutsubPlanID].numberOfProxies) + ' Proxies'
                }
                {checkoutPlanType != 'datacenter' && checkoutPlanType !== 'isp' && checkoutPlanType !== 'gmail' && checkoutPlanType !== 'nike' && checkoutPlanType !== 'server' &&
                  "Data - " + (Array.isArray(this.state.planData.subPlans) ? this.state.planData.subPlans.find(subplan => subplan.subplanId === checkoutsubPlanID).dataLimitMB : this.state.planData.subPlans[checkoutsubPlanID].dataLimitMB) / 1000 + ' GB'
                }
                {checkoutPlanType == 'gmail' &&
                  checkoutsubPlanID + ' - Accounts'
                }
                {nikeIspSubPlanData &&
                  <>
                    <p className="text-blue mt-2 mb-1">{checkoutsubPlanID} - {this.state.planData.description}</p>
                    <p className="text-blue mb-1">{nikeIspSubPlanData.numberOfProxies} - {this.state.ispPlans[this.state.planData['quantity'][checkoutsubPlanID].isp.planId].description} <span style={{ fontSize: '12px' }}> ({this.state.ispPlans.categories[nikeIspSubPlanData.category].name})</span></p>
                  </>
                }
                {checkoutPlanType === 'nike' && !nikeIspSubPlanData &&
                  checkoutsubPlanID + ' Accounts'
                }
              </p>
            </div>
            <div className="pd-2">
              <svg width="2" height="79" viewBox="0 0 2 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.29932 0.33374L1.29932 78.7983" stroke="#103A8D" strokeOpacity="0.42" />
              </svg>
            </div>

            <div className={totalDiv ? 'pd-3' : 'pd-3 d-none'} style={{ flexShrink: '0' }}>
              {checkoutPlanType !== 'nike' &&
                <h4 className="text-blue mb-1" style={{ textAlign: 'right' }}>Amount</h4>
              }
              {
                (checkoutPlanType === 'nike' && (!this.state.planData.isCombo || !this.state.planData['quantity'][checkoutsubPlanID].isp)) &&
                <h4 className="text-blue mb-1" style={{ textAlign: 'right' }}>Amount</h4>
              }
              <h2 className="fw-bold text-blue mb-0" style={{ fontWeight: '400' }}>$ {checkoutPlanPrice}</h2>
              {
                nikeIspSubPlanData &&
                <>
                  <p className="text-blue mt-1 mb-0" style={{ textAlign: 'right' }}>$ {this.state.planData['quantity'][checkoutsubPlanID].price}</p>
                  <p className="text-blue mt-0 mb-0" style={{ textAlign: 'right' }}>$ {ispSubplanPrice}</p>
                </>
              }
            </div>

            <div className={discountDiv ? '' : 'd-none'} style={{ flexShrink: '0' }}>
              <p className="text-blue mb-0" style={{ textAlign: 'right' }}>$ {checkoutPlanPrice}</p>
              <p className="text-danger mb-1" style={{ textAlign: 'right' }}>- $ {couponDiscount}</p>
              <h4 className="fw-bold text-blue mb-0" style={{ fontWeight: '400', textAlign: 'right' }}>$ {finalPrice.toFixed(2)}</h4>
            </div>

          </div>

          <div className="form-field mt-4 up-z">
            {
              !hideCheckCoupon && !flipdChecking && !isFlipdUser &&
              <div className="form-check mb-5 text-blue" style={{ padding: '0' }}>
                <div>Apply Discount Code</div>
                <div className="d-flex align-items-center mt-1" style={{ justifyContent: 'space-between', padding: '0' }}>
                  <input className="sliderValue range-slider__value h-100" type="text" id="couponCode" name="couponCode" value={couponCode} onChange={this.onChange} style={{ width: '60%', fontSize: '1.1rem', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '10px', paddingRight: '10px' }} />
                  <Button
                    disabled={isCouponInvalid}
                    color="default"
                    className="cta-white red-cta text-light"
                    style={{ fontSize: '1.2rem', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '30px', paddingRight: '30px', backgroundColor: 'var(--main-sky-blue)' }}
                    id="applyCouponBtn"
                    size="lg"
                    onClick={this.applyCouponCode}>Check</Button>
                </div>
              </div>
            }

            {
              !hideCheckCoupon && !flipdChecking && isFlipdUser &&
              <div className="form-check mb-4 text-blue" style={{ padding: '0' }}>
                <div className="d-flex align-items-center mt-1" style={{ justifyContent: 'space-evenly', padding: '0' }}>
                  <div>Applied Discount Code</div>
                  <div className="sliderValue range-slider__value h-100" style={{ width: '50%', fontSize: '1.1rem', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '10px', paddingRight: '10px', color: 'darkgray' }}>{couponCode}</div>
                </div>
              </div>
            }

            {
              isSubscription &&
              <div className="form-check form-switch mb-3">
                <Input
                  className="form-check-input w-custom"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  disabled={true}
                  defaultChecked={true}
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginTop: '4px' }}>Buy Subscription</label>
              </div>
            }

            <div className="form-check">
              <input className="form-check-input w-custom-2" type="checkbox" value="" id="flexCheckChecked" style={{ paddingLeft: '11px', paddingRight: '11px' }} onChange={this.termsConditionChecked} />
              <label className="form-check-label" htmlFor="flexCheckChecked" style={{ marginTop: '6px' }}>
                I agree to the <a target='__blank' href='/tos' style={{ color: '#0d6efd' }}>terms and conditions</a>
              </label>
            </div>

            <div className="up-z">
              <div className="text-end">
                <div className="mt-5 mb-3 text-center">
                  <Button
                    disabled={isCheckoutInvalid || flipdChecking}
                    color="default"
                    className="mb-3 px-5 cta-white red-cta text-light"
                    id="checkoutPayBtn"
                    size="lg"
                    onClick={this.doPlanCheckout}>
                    {
                      !hideCheckCoupon && <div className="spinner-grow text-light" role="status"></div>
                    }
                    {
                      hideCheckCoupon && `Pay $${this.state.checkoutPlanPrice}`
                    }
                  </Button>
                </div>
              </div>
            </div>
            <div className="up-z text-end">
              <img src={require("assets/images/powerd-strip.png")} alt="" />
            </div>
          </div>
        </div>

      </Modal>
    );
  }
}

export default withRouter(CheckoutModal);
