import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts/Firebase';
import Navbar from "components/Layout/Navbar";

import {
  showToastAlert
} from 'Services/Utility';


const Order = (props) => {
  const { user, firebase } = useContext(FirebaseContext);
  const history = useHistory();
  
  const [dcOrders, setDCOrders] = useState([]);
  const [ispOrders, setIspOrders] = useState([]);
  const [resiProOrders, setResiProOrders] = useState([]);
  const [premiumOrders, setPremiumOrders] = useState([]);
  const [activeResiTab, setActiveResiTab] = useState("pro");
  const [activeDCTab, setActiveDCTab] = useState("isp");

  const [activeProviderResi, setActiveProviderResi] = useState([]);
  const [activeProviderDC, setActiveProviderDC] = useState([]);

  useEffect(() => {
    // code to run after render goes here
    document.body.classList.add('dash-bg');
    props.setOpenedSideMenu('orders');
  }, []);


  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (!user) {
            history.push('/', { 'from': 'orders' });
            return;
          }
          firebase.db.ref('payments/' + user.uid).on('value', async (snapshot) => {
            const dbPlans = snapshot.val();
            let dcOrders = [];
            let ispOrders = [];
            let proOrders = [];
            let premiumOrders = [];
            if (dbPlans) {
              Object.keys(dbPlans).map(key => {
                if (dbPlans[key].planType == 'isp') {
                  ispOrders.push({
                    ...dbPlans[key],
                    purchaseDate: key,
                  });

                  if (dbPlans[key].renew) {
                    Object.keys(dbPlans[key].renew).map(time => {
                      ispOrders.push({
                        ...dbPlans[key].renew[time],
                        purchaseDate: time,
                      });
                    })
                  }
                } else if (dbPlans[key].planType == 'datacenter') {
                  dcOrders.push({
                    ...dbPlans[key],
                    purchaseDate: key,
                  });

                  if (dbPlans[key].renew) {
                    Object.keys(dbPlans[key].renew).map(time => {
                      dcOrders.push({
                        ...dbPlans[key].renew[time],
                        purchaseDate: time,
                      });
                    })
                  }
                } else if (dbPlans[key].planType == 'residential' && dbPlans[key].provider == 'pro') {
                  proOrders.push({
                    ...dbPlans[key],
                    purchaseDate: key,
                  });

                  if (dbPlans[key].renew) {
                    Object.keys(dbPlans[key].renew).map(time => {
                      proOrders.push({
                        ...dbPlans[key].renew[time],
                        purchaseDate: time,
                      });
                    })
                  }
                } else if (dbPlans[key].planType == 'residential' && dbPlans[key].provider == 'premium') {
                  premiumOrders.push({
                    ...dbPlans[key],
                    purchaseDate: key,
                  });

                  if (dbPlans[key].renew) {
                    Object.keys(dbPlans[key].renew).map(time => {
                      premiumOrders.push({
                        ...dbPlans[key].renew[time],
                        purchaseDate: time,
                      });
                    })
                  }
                }
              });
            }

            setIspOrders(ispOrders);
            setDCOrders(dcOrders);
            setResiProOrders(proOrders);
            setPremiumOrders(premiumOrders);

            setActiveProviderResi(proOrders);
            setActiveProviderDC(ispOrders);

            console.log("Orders loaded.");
          });

        } catch (error) {
          console.log(error);
          showToastAlert('Error', error.message);
        }
      });
    })();
  }, [history, firebase.auth]);

  return (
    <div id="page-content-wrapper" className="extra-p">

      <Navbar toggleSideMenu={props.toggleSideMenu} />

      <div class="dash-inner table-part">
        <div class="row">
        </div>
        <div class="row">
          <div class="col-xl-6 mt-5">
            <div class="dash-head mb-0">
              <h4 class="mb-0">Residential</h4>
            </div>

            <div class="plan-nav">
              <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class={activeResiTab === 'pro' ? "nav-link px-5 active" : "nav-link px-5"} onClick={e => { setActiveProviderResi(resiProOrders); setActiveResiTab('pro') }} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile21" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Pro</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class={activeResiTab === 'premium' ? "nav-link px-5 active" : "nav-link px-5"} onClick={e => { setActiveProviderResi(premiumOrders); setActiveResiTab('premium') }} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile21" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Premium</button>
                </li>
              </ul>
            </div>

            <div class="dash-box position-relative mt-2">

              <div class="dash-head mb-0">
                <h4 class="mb-0">Order History</h4>
              </div>

              <div class="table-responsive mt-2">
                <table class="table table-hover table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Order</th>
                      <th scope="col">Provider</th>
                      <th scope="col">Package</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">Price</th>
                      <th scope="col">Paid</th>
                      <th scope="col">Data</th>

                    </tr>
                  </thead>
                  <tbody>
                    {activeProviderResi && activeProviderResi.length > 0 && activeProviderResi.map(order => {
                      return (
                        <tr>
                          <td scope="row">{order.authToken || order.username}</td>
                          <td>{order.planType}</td>
                          <td>{order.planName}</td>
                          <td>{new Date(parseInt(order.purchaseDate) * 1000).toDateString()}</td>
                          <td>{order.planPrice}</td>
                          <td>{order.paid}</td>
                          <td>{order.dataLimit} GB</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-xl-6 mt-5">
            <div class="dash-head mb-0">
              <h4 class="mb-0">ISP/DC</h4>
            </div>

            <div class="plan-nav">
              <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class={activeDCTab === 'isp' ? "nav-link px-5 active" : "nav-link px-5"} onClick={e => { setActiveProviderDC(ispOrders); setActiveDCTab('isp') }} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home211" type="button" role="tab" aria-controls="pills-home" aria-selected="true">ISP</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class={activeDCTab === 'dc' ? "nav-link px-5 active" : "nav-link px-5"} onClick={e => { setActiveProviderDC(dcOrders); setActiveDCTab('dc') }} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile211" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">DC</button>
                </li>
              </ul>
            </div>

            <div class="dash-box position-relative mt-2">

              <div class="dash-head mb-0">
                <h4 class="mb-0">Order History</h4>
              </div>

              <div class="table-responsive mt-2">
                <table class="table table-hover table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Order</th>
                      <th scope="col">Provider</th>
                      <th scope="col">Package</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">Price</th>
                      <th scope="col">Paid</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeProviderDC && activeProviderDC.length > 0 && activeProviderDC.map(order => {
                      return (
                        <tr>
                          <td scope="row">{order.authToken || order.username}</td>
                          <td>{order.planType}</td>
                          <td>{order.planName}</td>
                          <td>{new Date(parseInt(order.purchaseDate) * 1000).toDateString()}</td>
                          <td>{order.planPrice}</td>
                          <td>{order.paid}</td>
                          <td>{order.dataLimit} GB</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Order;
