import React, { useEffect, useState } from 'react';
import firebase from "../Services/Firebase";
const FirebaseContext = React.createContext(null);


const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(firebase.auth.currentUser || null);
  useEffect(() => {
    firebase.auth.onAuthStateChanged(user => setUser(user));
  }, []);

  return (
    <FirebaseContext.Provider value={{ user, setUser, firebase }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export {
  FirebaseContext,
  FirebaseProvider
};