import React, { useState, useEffect, useContext, Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from "react-router-dom";
import * as request from 'request';
import $ from 'jquery';

import { FirebaseContext } from 'contexts/Firebase';
import {
    BlackCheckoutModal,
    showToastAlert,
    parseURLParams
} from 'Services/Utility';


const UltimateGuideNikeProxiesArticle = (props) => {

    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [hasDiscordId, setHasDiscordId] = useState(false);
    const [ispPlans, setIspPlans] = useState({});
    const [checkoutModals, setCheckoutModals] = useState([]);
    const [ispDcStocksLeft, setIspDcStocksLeft] = useState({});
    const [nikeStocksLeft, setNikeStocksLeft] = useState(0);

    const planTypes = ['isp', 'residential'];
    const planIds = ['plan_14', 'plan_3'];

    const getIspDcStocksLeft = () => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/isp/inStockDC`,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    return resolve({});
                } else {
                    if (resData.data) return resolve(resData.data);
                    return resolve({});
                }
            });
        });
    }

    const getNikeStocksLeft = () => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/nike/allocationleft`,
                headers: { 'Content-Type': 'application/json' }
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    return resolve(0);
                } else {
                    if (resData.inStock) return resolve(resData.inStock);
                    return resolve(0);
                }
            });
        });
    }

    const openCheckoutModal = async (planType, planID, subPlanID) => {
        try {
            setCheckoutModals([]);

            if (!user) {
                history.push('/login');
                return;
            }
            if (!hasDiscordId) {
                return showToastAlert('Error', "Please verify your discord account");
            }

            let planData = ispPlans[planType][planID];
            if (!planData) return;

            setCheckoutModals([
                <BlackCheckoutModal
                    showModal={true}
                    checkoutPlanID={planID}
                    checkoutsubPlanID={subPlanID}
                    checkoutPlanType={planType}
                    planData={planData}
                    ispPlans={ispPlans['isp']}
                    key={Math.random()}
                    firebase={firebase}
                />
            ]);
        } catch (error) {
            showToastAlert('Error', error.message);
        }
    }

    useEffect(() => {
        (async function () {
            // const [ispDcLeft, nikeLeft] = await Promise.all([getIspDcStocksLeft(), getNikeStocksLeft()]);
            // setIspDcStocksLeft(ispDcLeft);
            // setNikeStocksLeft(nikeLeft);

            firebase.db.ref('plans').on('value', async (snapshot) => {
                const dbPlans = snapshot.val();
                setIspPlans(dbPlans);

                var firstTop = $('#first-circle').offset().top;
                var lastTop = $('#last-circle').offset().top;
                $('.hero .left-hero span').height(`${lastTop - firstTop - 15}px`);
            });
        })();
    }, []);

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (user) {
                        const discordId = (await firebase.db.ref(`users/${user.uid}/discordId`).once('value')).val();
                        if (discordId && discordId !== '') {
                            setHasDiscordId(true);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                }
            });
        })();
    }, []);

    useEffect(() => {
        let urlString = window.location.href;
        let urlParams = parseURLParams(urlString);
        let currentUrl = urlString.split('?')[0];
        window.history.replaceState({}, document.title, currentUrl);
        if (urlParams) {
            if (urlParams.status && urlParams.status[0] === 'success') {
                try {
                    showToastAlert('success', 'Discord successfully verified');
                } catch (error) {
                    showToastAlert('Error', 'Error while verifying Discord');
                }
            } else if (urlParams.message) {
                showToastAlert('Error', urlParams.message[0]);
            }
        }
    }, []);


    return (
        <>
            <div className="container mt-5">
                <div className="row hero-row">
                    <div className="col-12 text-light left-hero">
                        <span></span>
                        <h1 className="fw-bold main-title circle-big" id="first-circle">
                            Ultimate Guide to Nike Proxies | Unlock the Secret to Sneaker Success!
                        </h1>
                        <p className="mt-4 mb-4">
                            Discover the Key to Unrestricted Sneaker Shopping with Our Guide to Nike Proxies. Love shopping for Nike sneakers but tired of limitations? We've got you covered. Our complete guide to Nike proxies will show you why they're essential, how they can improve your shopping experience, and give you tips on choosing the best proxy for your needs. Say goodbye to frustration and hello to effortless shopping. Let's begin!
                        </p>
                    </div>
                </div>
            </div>

            <div className="container pt-5">
                <div className="row hero-row">
                    <div className="col-12 text-light">
                        <h2 className="fw-bold main-title circle-big position-relative section-2-custom-set">
                            What are Nike Proxies?
                        </h2>
                        <p className="mt-4 mb-4">
                            Nike Proxies are an essential tool for sneaker enthusiasts and resellers looking to increase their chances of getting their hands on limited-edition Nike sneakers. With Nike only releasing these exclusive kicks in small quantities in specific regions, it can be a challenge for enthusiasts to purchase them. To overcome this, individuals often use bots and proxies to boost their chances of success.
                        </p>
                        <p className="mt-3 mb-4">
                            Bots are automated software designed to make online purchases, while proxies are used to mask the user's IP address, making it appear as if the user is accessing the site from a different location. This is especially important for sneaker botters, who often use multiple accounts with different billing information to bypass Nike's strict one purchase per account policy. Without the use of proxies, Nike can easily trace these multiple accounts back to the same IP address, resulting in a ban. Proxies help mask the IP address, making it seem like each account is being accessed from a different device, and help avoid detection and potential bans. With the use of Nike Proxies, sneaker enthusiasts and resellers can purchase limited-edition Nike sneakers from any location, regardless of geographical restrictions. By using proxies, individuals can increase their chances of success in purchasing multiple limited-edition Nike sneakers.
                        </p>
                    </div>
                </div>
                <div className="row hero-row">
                    <div className="col-12">
                        <img src={require("assets/images/blogs/stella-proxies-map-ip.png")} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>

            <div className="container pt-5">
                <div className="row hero-row">
                    <div className="col-12 text-light">
                        <h2 className="fw-bold main-title circle-big position-relative section-2-custom-set">
                            What are the different types of Nike Proxies?
                        </h2>
                        <p className="mt-4 mb-4">
                            The most effective proxies for Nike SNKRS are residential or ISP proxies. Residential proxies come from real residential users and their devices connected to WiFi, while ISP proxies are datacenter IP addresses registered under internet service providers. The security on Nike stores is very tight, so it is important to choose proxies that look as genuine as possible.
                        </p>
                        <p className="mt-3 mb-4">
                            When deciding between residential and ISP proxies, it is important to keep in mind that Nike's site does not experience a high level of traffic, making the pricing model of residential IPs (pay for traffic) less of a concern. The speed of the proxies is also not a critical factor, as users will typically be in a queue waiting to purchase the sneakers. However, the proxies should still be reasonably fast.
                        </p>
                        <p className="mt-3 mb-4">
                            The two key factors to consider when choosing Nike SNKRS proxies are rotation time and location. It is important to use proxies located in the same country as the Nike store being targeted, and the rotation time should be at least 10 minutes, with 30 minutes being the ideal. If the IP address changes while waiting in the queue, the user may be removed.
                        </p>
                    </div>
                </div>
                <div className="row hero-row">
                    <div className="col-12">
                        <img src={require("assets/images/blogs/stella-proxies-performance.png.jpg")} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>

            <div className="container pt-5">
                <div className="row hero-row">
                    <div className="col-12 text-light">
                        <h2 className="fw-bold main-title circle-big position-relative section-2-custom-set">
                            What are the benefits of using Nike Proxies?
                        </h2>
                        <p className="mt-4 mb-4">
                            Nike's limited edition sneakers are highly sought after and are often released in small quantities in specific regions. This makes it challenging for sneaker enthusiasts to get their hands on these exclusive sneakers.
                        </p>
                        <p className="mt-3 mb-4">
                            To increase their chances of purchasing limited edition Nike sneakers, many individuals use bots and proxies. Bots are automated software designed to make online purchases, and proxies are used to mask the user's IP address, making it appear as if the user is accessing the site from a different location.
                        </p>
                        <p className="mt-3 mb-4">
                            Reselling limited edition Nike sneakers can be lucrative, with a single pair often costing over $2000 on reseller sites. However, Nike has a strict policy of only allowing one purchase per account. To bypass this policy, multiple accounts with different billing information and a sneaker bot are necessary. Without the use of proxies, Nike can easily trace these multiple accounts back to the same IP address and issue a ban. Proxies help to mask the IP address and make it seem like each account is being accessed from a different device.
                        </p>
                        <p className="mt-3 mb-4">
                            It is important to note that bot usage is against Nike's policies, and their activities can be easily detected. In this case, proxies are necessary to mask the bot and avoid detection and potential bans.
                        </p>
                        <p className="mt-3 mb-4">
                            With the use of proxies, sneaker enthusiasts can purchase limited edition Nike sneakers from any location, regardless of geographical restrictions. This means that individuals living outside of the designated release region can still get their hands on these exclusive sneakers. By using proxies, individuals can increase their chances of success in purchasing multiple limited edition Nike sneakers.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container pt-5">
                <div className="row hero-row">
                    <div className="col-12 text-light">
                        <h2 className="fw-bold main-title circle-big position-relative section-2-custom-set">
                            How can I use Nike Proxies?
                        </h2>
                        <p className="mt-4 mb-4">
                            Now let’s address the elephant in the room, anyone can use Nike proxies with help of a good service provider. And when Nike proxy providers are concerned, why to even look at anywhere other than Stella Proxies?
                        </p>
                        <p className="mt-3 mb-4">
                            Here at <a href="https://stellaproxies.com/">Stella Proxies</a> we ensure that your online activities are protected and secure, keeping your personal and sensitive information safe from prying eyes.
                        </p>
                        <p className="mt-3 mb-4">
                            Not just that, we always put our customer experience first and provide an enjoyable smooth experience by routing your internet traffic through a fast and reliable proxy server.
                        </p>
                        <p className="mt-3 mb-4">
                            And lastly, you can significantly increase your chances of successfully buying sneakers online using Nike proxies that will help to disguise your IP address and make it appear like each purchase is coming from a different individual. This way, Nike won't suspect anything and you have 100% chances of a successful purchase without getting flagged on purchasing multiple limited edition items.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container pt-5">
                <div className="row hero-row">
                    <div className="col-12 text-light">
                        <h2 className="fw-bold main-title circle-big position-relative section-2-custom-set">
                            What are the different plans for Nike Proxies?
                        </h2>
                        <p className="mt-4 mb-4">
                            Although we have a variety of options to choose including ISP, DC, Residential, and CAPTCHA proxies, there are two main plans for Nike proxies.
                        </p>
                        <ul className='subheadline mb-4'>
                                <li>Nike ISP</li>
                                <li>Nike Premium</li>
                            </ul>
                        <h3 className="mt-3">
                            Nike ISP
                        </h3>
                        <p className="mt-4 mb-4">
                            Nike ISP provides instant delivery with fully optimized dedicated ISP proxies for Nike and SNKRS with a 100 GBPS Network and unlimited bandwidth.
                        </p>
                        <h3>
                            Nike Premium
                        </h3>
                        <p className="mt-4 mb-4">
                            Nike premium gives upstream technology with private pools and unlimited residential IPs.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                    <div className="row hero-row mt-5">
                        <div className="col-12">
                            <h2 className="fw-bold main-title circle-big position-relative section-2-custom-set">
                                Pricing
                            </h2>
                        </div>
                    </div>

                    <div className="proxy-network-details pricing-details">
                        <div className="pricing-details" id='pricing-details'>
                            <div className="tab-content">
                                <div id="isp" role="tabpanel" aria-labelledby="isp-tab">
                                    <div className="row mt-2">
                                        {
                                            Object.keys(ispPlans).length && planIds.flatMap((planId, idx) => {
                                                const plantype = planTypes[idx];
                                                
                                                let arr = [ispPlans[plantype][planId]];
                                                
                                                return arr.map(plan => {
                                                    if (plan.bulk || plan.hide || plan.onlybot) return null;
                                                    return <PriceCard key={`pc_${plantype}-${plan.planId}`} openCheckoutModal={openCheckoutModal} planType={plantype} planData={plan} isActive={ispPlans[plantype].active} categories={ispPlans[plantype].categories} planID={planId} stocksLeft={ispDcStocksLeft} nikeStocksLeft={nikeStocksLeft} ispPlans={ispPlans['isp']} ></PriceCard>
                                                })
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div className="container pt-5">
                <div className="row hero-row">
                    <div className="col-12 text-light">
                        <h2 className="fw-bold main-title circle-big position-relative section-2-custom-set" id="last-circle">
                            Conclusion
                        </h2>
                        <p className="mt-4 mb-4">
                            In conclusion, Nike Proxies offer a valuable asset for sneaker collectors and resellers looking to gain a competitive edge in the world of sneakers. By breaking down geographical barriers and enhancing online security, these proxies provide access to exclusive releases and limited-edition sneakers.
                        </p>
                        <p className="mt-3 mb-4">
                            Stella Proxies offer a solution to access these coveted releases, but it is important to note that using Nike Proxies may go against Nike's terms of service and result in a ban on the user's account. However, Stella Proxies guarantees a fast, smooth, and secure experience accessing the Nike website.
                        </p>
                        <p className="mt-3 mb-4">
                            Don't miss out on the opportunity to gain a competitive advantage. Sign up for <a href="https://stellaproxies.com/">Stella Proxies</a> today and secure your next must-have pair of hyped sneakers/apparel before anyone else.
                        </p>
                    </div>
                </div>
            </div>

            {checkoutModals.map(modal => (
                modal
            ))}
        </>
    )
}

class PriceCard extends Component {

    constructor(props) {
        super(props);
        let stateJson = {
            planType: props.planType,
            planData: props.planData,
            isActive: props.isActive,
            categories: props.categories,
            selectedCategory: props.selectedCategory,
            planID: props.planID,
            subPlanID: '',
            planName: props.planData.name,
            features: props.planData.features,
            hide: props.planData.hide,
            stocksLeft: props.stocksLeft || {},
            selectedQty: '',
            ispPlans: props.ispPlans,
            nikeStocksLeft: props.nikeStocksLeft || 0
        };

        let subPlans = {};
        let categories = {};
        for (const subplanId in stateJson.planData.subPlans) {
            const subplan = stateJson.planData.subPlans[subplanId];
            if (!subplan.hide) {
                subPlans[subplanId] = subplan;
                categories[subplan.category] = stateJson.categories[subplan.category];
            }
        }
        stateJson.planData.subPlans = subPlans;
        stateJson.categories = categories;
        const subPlanKeys = Object.keys(subPlans);
        if (subPlanKeys.length) {
            stateJson['subPlanID'] = subPlanKeys[0];
            stateJson['price'] = subPlans[subPlanKeys[0]].price;
            stateJson['selectedCategory'] = subPlans[subPlanKeys[0]].category;

            if (stateJson.planType === 'residential') {
                stateJson['isActive'] = stateJson.isActive && stateJson.planData.active && subPlans[subPlanKeys[0]].active;
            } else {
                stateJson['isActive'] = stateJson.isActive && stateJson.planData.active && subPlans[subPlanKeys[0]].active && stateJson.stocksLeft[stateJson.planData['servername']] >= subPlans[subPlanKeys[0]].numberOfProxies;
            }
        }
        else {
            stateJson['hide'] = true;
        }

        this.state = stateJson;
        this.onSubPlanChange = this.onSubPlanChange.bind(this);
        this.purchaseInit = this.purchaseInit.bind(this);
    }

    onSubPlanChange(event) {
        let subid = event.target.value;
        let isactive = false;

        if (this.state.planType === 'residential') {
            isactive = this.props.isActive && this.state.planData.active && this.state.planData.subPlans[subid].active;
        } else {
            isactive = this.props.isActive && this.state.planData.active && this.state.planData.subPlans[subid].active && this.state.stocksLeft[this.state.planData['servername']] >= this.state.planData.subPlans[subid].numberOfProxies;
        }
        this.setState(state => ({
            price: this.state.planData.subPlans[subid].price,
            subPlanID: subid,
            selectedCategory: this.state.planData.subPlans[subid].category,
            isActive: isactive
        }));
    }

    purchaseInit() {
        this.props.openCheckoutModal(this.state.planType, this.state.planID, this.state.subPlanID);
    }

    render() {
        const { hide } = this.state;
        if (hide) {
            return ''
        }
        else {
            return (
                <div className="col-md-4 mt-4">
                    <div className="price-box">
                        <h2 className="text-center fw-bold">{this.state.planName}</h2>

                        <div className="main-price" style={{ fontWeight: 500 }}>${this.state.price}
                            <span style={{ fontWeight: 300, fontSize: '0.69rem' }}> / {this.state.categories[this.state.selectedCategory].name}</span>
                        </div>

                        <div className="price-feature">
                            <ul className="bullet mt-4 mb-0">
                                {ReactHtmlParser(this.state.features)}
                            </ul>
                        </div>

                        <div className="plan-select mt-4">
                            <select id="inputState" className="form-select" value={this.state.subPlanID} onChange={this.onSubPlanChange}>
                                {Object.keys(this.state.planData.subPlans).map((subId) => {
                                    let subplan = this.state.planData.subPlans[subId];
                                    if (subplan.hide) { return; }
                                    if (this.state.planType === 'residential') {
                                        return <option value={subId}>{`${parseInt(subplan.dataLimitMB) / 1000} GB`}</option>
                                    } else {
                                        if (subplan.category == this.state.selectedCategory) {
                                            return <option value={subId} >{subplan.numberOfProxies} Proxies</option>
                                        }
                                    }
                                })}
                            </select>
                        </div>

                        {this.state.isActive ? <a href="#" className="purchase-btn mt-4" onClick={this.purchaseInit}>Purchase</a> :
                            <a href="#" className="purchase-btn mt-4 bg-secondary" style={{ cursor: 'not-allowed' }}>Sold Out</a>}
                    </div>
                </div>
            )
        }
    }
}

export default UltimateGuideNikeProxiesArticle;
