import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

import firebaseConfig from "./FirebaseConfig";


class Firebase {
  static firebase;
  constructor() {
    app.initializeApp(firebaseConfig);
    this.analytic = app.analytics();
    this.auth = app.auth();
    this.app = app;
    this.db = app.database();
    this.provider = new app.auth.GoogleAuthProvider();
    this.clearState = false;
  }

  static getInstance() {
    if (!Firebase.firebase) {
      Firebase.firebase = new Firebase();
    }
    return Firebase.firebase;
  }


  doGoogleLogin = () => this.auth.signInWithPopup(this.provider);

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
}

let firebase = Firebase.getInstance();
export default firebase;