import React, { Component,useEffect } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as request from 'request';
import { showToastAlert } from './Utility';
import $ from 'jquery';

const INITIAL_STATE = {
    showModal: false
};
var firebase = null;
var user = null;

class OTPModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false,
            enabled: props.enabled || false,
            OTPSent: props.OTPSent || false,
            verificationId: props.verificationId || null,
            resolver: props.resolver || null
        };
        firebase = props.firebase;
        user = props.user;
        this.closeModal = this.closeModal.bind(this);
        console.log(this.state);
      
        
    }

    componentDidMount = () => {
        setTimeout(this.loginWith2fa, 2000);
    }

    closeModal = () => {
        this.setState({
            ...INITIAL_STATE
        });
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

   

    clearPassword = async (event) => {
        $('#newPwd').val('');
        $('#repeatPwd').val('');
    }

    loginWith2fa = async () => {
        if(this.props.resolver){
            try {
                var phoneInfoOptions = {
                    multiFactorHint: this.props.resolver.hints[0],
                    session: this.props.resolver.session
                };
                var recaptchaVerifier = new firebase.app.auth.RecaptchaVerifier('recaptcha-container',  {
                    "size": "invisible"                   
                });
                var phoneAuthProvider = new firebase.app.auth.PhoneAuthProvider();
                var verificationId =  await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
                console.log("OTP Sent");
                this.setState({OTPSent : true, enabled: false, verificationId: verificationId});

             
            } catch(error) {
                console.log(error);
                showToastAlert('Error', error.message);
            }
           
        }
    }

    enable2fa = async (otp) => {
        var cred = firebase.app.auth.PhoneAuthProvider.credential(this.state.verificationId, otp);
        var multiFactorAssertion = firebase.app.auth.PhoneMultiFactorGenerator.assertion(cred);
        await user.multiFactor.enroll(multiFactorAssertion, this.state.mobileNumber);

        this.setState({
            enabled: true, showModal: false
        });
        showToastAlert('Success',"Two step verification is enalbed.");
        window.location.reload(false);

    }

    sendOTP = async (event) => {
        const number = $('#mobileNumber').val().trim();
        console.log("Sending OTP");

        try {
            var recaptchaVerifier = new firebase.app.auth.RecaptchaVerifier('recaptcha-container', {
                "size": "invisible"
                });
            var verificationId = await this.props.user.multiFactor.getSession().then(async function(multiFactorSession) {
                // Specify the phone number and pass the MFA session.
                var phoneInfoOptions = {
                  phoneNumber: number,
                  session: multiFactorSession
                };
                var phoneAuthProvider = new firebase.app.auth.PhoneAuthProvider();
                // Send SMS verification code.
                return phoneAuthProvider.verifyPhoneNumber(
                    phoneInfoOptions, recaptchaVerifier);

                
              })

              this.setState({
                OTPSent: true,
                verificationId: verificationId,
                mobileNumber: number
            });

            
        } catch (error) {
            console.log(error);
            showToastAlert('Error', error.message);
        }
       
    }

    submitOTP = async (event) => {
        console.log("Submiting OTP");
        const otp = $('#otp').val().trim();
        console.log(this.state.verificationId);
        if(this.props.resolver){
            this.verifyOTP(otp);
        }else{
            this.enable2fa(otp);
        }
    }

    verifyOTP = async (otp) => {
        var cred = firebase.app.auth.PhoneAuthProvider.credential(
            this.state.verificationId, otp);
        var multiFactorAssertion =
            firebase.app.auth.PhoneMultiFactorGenerator.assertion(cred);
        this.state.resolver.resolveSignIn(multiFactorAssertion);
    }

    render() {
        const { showModal, enabled, OTPSent } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                backdrop="static"
                size="md"
                centered
            >
                <div id="recaptcha-container"></div>

                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/head-profile.svg")} alt="" /> <h4 className="mb-0">Two Factor Authentication</h4>
                    </div>
                    <p>Please use mobile number along with your country code. For example, +12538732333</p>                    

                    {
                        !enabled && !OTPSent &&
                        <div className="form-field">
                            <div className="form-group row mb-3">
                                <label htmlFor="mobileNumber" className="col-sm-4 col-form-label">Mobile Number</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" id="mobileNumber" />
                                </div>
                            </div>                           
                            <div className="row up-z">
                                <div className="col-sm-12 text-end">
                                    <div className="d-inline-block mt-4 me-2">
                                        <a href="#" className="mb-3 px-5 cta-white" onClick={this.clearPassword}>Clear</a>
                                    </div>
                                    <div className="form-cta-btn d-inline-block mt-4">
                                        <a href="#" className="mb-3 px-5 text-light" onClick={this.sendOTP}>Enable</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    }
                    {
                        !enabled && OTPSent &&
                        <div className="form-field">
                            <div className="form-group row mb-3">
                                <label htmlFor="otp" className="col-sm-4 col-form-label">OTP</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" id="otp" />
                                </div>
                            </div>                           
                            <div className="row up-z">
                                <div className="col-sm-12 text-end">
                                    <div className="d-inline-block mt-4 me-2">
                                        <a href="#" className="mb-3 px-5 cta-white" onClick={this.clearPassword}>Clear</a>
                                    </div>
                                    <div className="form-cta-btn d-inline-block mt-4">
                                        <a href="#" className="mb-3 px-5 text-light" onClick={this.submitOTP}>Submit OTP</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    
                </div>

            </Modal>
        );
    }
}

export default withRouter(OTPModal);
