import React, { useState, useEffect, useContext } from 'react';
import $ from 'jquery';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts/Firebase';
import Navbar from "components/Layout/Navbar";
import {
  CheckoutModal,
  showToastAlert,
  DoughnutChart,
  generateRandomString,
  copyData,
  downloadFile,
  parseURLParams
} from 'Services/Utility';


const Dashboard = (props) => {

  const { user, firebase } = useContext(FirebaseContext);
  const history = useHistory();
  const [hasDiscordId, setHasDiscordId] = useState(false);
  const [categories, setCategories] = useState({});
  const [activeTabKey, setActiveTabKey] = useState('premium');
  const [resiPlans, setResiPlans] = useState({});
  const [sortedResiSubPlans, setSortedResiSubPlans] = useState({});
  const [proxyType, setProxyType] = useState('sticky');
  const [selectedCountry, setSelectedCountry] = useState();
  const [numberOfProxies, setNbrOfProxies] = useState(0);
  const [generatedProxies, setGeneratedProxies] = useState('');
  const [checkoutModals, setCheckoutModals] = useState([]);
  const [enableOffer, setEnableOffer] = useState(false);
  const [claiming, setClaiming] = useState(false);

  const [continents, setContinents] = useState({});
  const [countries, setCountries] = useState([]);

  const [userPlans, setUserPlans] = useState({});
  const [selectedUserPlans, setSelectedUserPlans] = useState({});
  const [userSubPlanKey, setUserSubPlanKey] = useState({});
  const [userCharts, setUserCharts] = useState({});

  const [currentPlan, setCurrentPlan] = useState({});
  const [currentPlansData, setCurrentPlansData] = useState({});

  const [swapState, setSwapState] = useState(0);
  const [currentProxies, setCurrentProxies] = useState([]);

  const providers = [{ 'name': 'Premium', 'key': 'premium' }, { 'name': 'Pro', 'key': 'pro' }];

  const settings = {
    fill: '#703BDD',
    background: '#FFFFFF'
  }

  const changeAuthToken = (e) => {
    e.persist();
    setProxyType('sticky');
    setGeneratedProxies('');
    setCurrentProxies([]);
    const selectedPlan = userPlans[activeTabKey].data[e.target.value];
    setSelectedUserPlans(plans => ({ ...plans, [activeTabKey]: { ...selectedPlan, authToken: e.target.value } }));
  }

  const changeCountry = (e) => {
    e.persist();
    setSelectedCountry(JSON.parse(e.target.value));
  }

  const changeContinent = (e) => {
    e.persist();
    setCountries(continents[e.target.value]);
    setSelectedCountry(continents[e.target.value][0]);
    $('#countrySelect').prop("selectedIndex", 0).trigger('change');
  }

  const changeSubPlan = (e) => {
    e.persist();
    const provider = e.target.getAttribute('data-provider');
    const data = JSON.parse(e.target.value);
    setUserSubPlanKey(plans => ({ ...plans, [provider]: data }));
  }

  const changeProxyType = (e) => {
    e.persist();
    setProxyType(e.target.value);
  }

  const changeActiveTab = async (e) => {
    e.persist();
    const key = e.target.getAttribute('data-key');
    setActiveTabKey(key);

    setContinents({});
    setCountries([]);
    setSelectedCountry();
    setNbrOfProxies(0);
    setProxyType('sticky');
    setGeneratedProxies('');
    setCurrentProxies([]);
    currentPlanSelection(userPlans[key] || {});
  }

  const generateProxies = () => {
    if (selectedCountry && currentPlan && currentPlan.planData && currentPlan.planData.status === 'active') {
      let createDate = new Date(currentPlan.planData.createDate);
      let remainHrs = parseInt(((createDate.setHours(createDate.getHours() + parseInt(currentPlan.planData.validityHours))) - new Date().getTime()) / (1000 * 60 * 60)) || 0;
      if (remainHrs > 0) {
        let proxyArr = [];
        if (proxyType == 'sticky') {
          for (let i = 1; i <= parseInt(numberOfProxies); i++) {
            proxyArr.push(selectedCountry.domain + ":" + Math.floor(Math.random() * (selectedCountry.portEnd - selectedCountry.portStart + 1) + selectedCountry.portStart) + ':' + currentPlan.planData.name + ":" + currentPlan.planData.pass + '-' + generateRandomString(10));
          }
        } else {
          for (let i = 1; i <= parseInt(numberOfProxies); i++) {
            proxyArr.push(selectedCountry.domain + ":" + Math.floor(Math.random() * (selectedCountry.portEnd - selectedCountry.portStart + 1) + selectedCountry.portStart) + ':' + currentPlan.planData.name + ":" + currentPlan.planData.pass);
          }
        }
        setGeneratedProxies(proxyArr.join('\r\n'));
        setCurrentProxies(proxyArr);
        setSwapState(0);
      }
    }
  }

  const copyProxies = () => {
    if (currentPlan && currentPlan.authToken && generatedProxies !== '') {
      copyData(generatedProxies);
    }
  }

  const downloadProxies = () => {
    if (currentPlan.authToken && generatedProxies !== '') {
      downloadFile(currentPlan.authToken + ".txt", generatedProxies);
    }
  }

  const newPlan = (subPlanData, currentPlan) => {
    if (!hasDiscordId) {
      return showToastAlert('Error', "Please verify your discord account");
    }
    if (!subPlanData || Object.keys(subPlanData).length == 0) {
      return showToastAlert('Info', "Please select your plan");
    }
    openCheckoutModal('residential', subPlanData.planKey, subPlanData.subPlanKey, currentPlan)
  }

  const renewPlan = (subPlanData, currentPlan) => {
    if (!hasDiscordId) {
      return showToastAlert('Error', "Please verify your discord account");
    }
    if (!subPlanData || Object.keys(subPlanData).length == 0) {
      return showToastAlert('Info', "Please select your plan");
    }
    openCheckoutModal('residential', subPlanData.planKey, subPlanData.subPlanKey, currentPlan, true)
  }

  const openCheckoutModal = async (planType, planID, subPlanID, currentPlan, renew = false) => {
    try {
      setCheckoutModals([]);
      if (!user) {
        showToastAlert('Error', 'Log in required to purchase a plan');
        return;
      }

      let showModal = true;
      let planData = resiPlans[planID];
      if (!planData) { return; }

      setCheckoutModals([
        <CheckoutModal
          showModal={showModal}
          checkoutPlanID={planID}
          checkoutsubPlanID={subPlanID}
          checkoutPlanType={planType}
          planData={planData}
          key={Math.random()}
          updatePlanRefID={renew && currentPlan && currentPlan.updatePlanRefID ? currentPlan.updatePlanRefID : null}
          firebase={firebase}
        />
      ]);
    } catch (error) {
      showToastAlert('Error', error.message);
    }
  }

  const configurePlanData = (resiData) => {
    if (resiData && resiData.status === 'success') {
      let keys = (resiData.data && Object.keys(resiData.data)) || [];
      if (keys.length > 0) {
        let plan = resiData.data[keys[0]];
        plan = { ...plan, authToken: keys[0] };
        if (plan.provider) {
          setUserPlans(plans => ({ ...plans, [plan.provider]: resiData }));
          setSelectedUserPlans(plans => ({ ...plans, [plan.provider]: plan }));
          if (plan.provider === activeTabKey) {
            currentPlanSelection(resiData);
          }
        }
      }
    }
  }

  const swapProxies = async () => {
    if (currentProxies.length > 0) {
      if (swapState === 0) {
        const domain = currentProxies[0].split(':')[0];
        const ips = await getIpsFromDomain(domain);
        console.log(ips);
        if (ips.length > 0) {
          let proxyArr = [];
          for (let i = 0; i < currentProxies.length; i++) {
            const splits = currentProxies[i].split(':');
            const randIp = ips[Math.floor(Math.random() * ips.length)];
            proxyArr.push(randIp + ":" + splits[1] + ':' + splits[2] + ":" + splits[3]);
          }
          setGeneratedProxies(proxyArr.join('\r\n'));
          setSwapState(1);
        }
      }
      else {
        setGeneratedProxies(currentProxies.join('\r\n'));
        setSwapState(0);
      }
    }
  }

  const currentPlanSelection = (plans) => {

    setCurrentPlansData(plans);
    let keys = (plans.data && Object.keys(plans.data)) || [];
    if (keys.length > 0) {
      let plan = plans.data[keys[0]];
      plan = { ...plan, authToken: keys[0] };
      setSelectedUserPlans(plans => ({ ...plans, [plan.provider]: plan }));
    }

    if (plans.countries && plans.countries.length > 0) {
      const groupContinent = plans.countries.reduce(function (r, a) {
        r[a.continent] = r[a.continent] || [];
        r[a.continent].push(a);
        return r;
      }, Object.create(null));
      setContinents(groupContinent);

      if (Object.keys(groupContinent).length) {
        const countries = groupContinent[Object.keys(groupContinent)[0]];
        setCountries(countries);
        setSelectedCountry(countries[0]);
      }
    }

    let slider = document.querySelector('.sliderInput');
    slider.value = 0;
    applyFill(slider);
    $('.sliderValue').val('');

    $('select').each(function () {
      $(this).prop("selectedIndex", 0).trigger('change');
    });
  }

  const getIpsFromDomain = (domain) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'GET',
        url: `https://dns.google/resolve?name=${domain}`
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        const ips = resData.Answer.flatMap(x => x.data);
        return resolve(ips || []);
      });
    });
  }

  // const getBasicPlans = (idToken) => {
  //   return new Promise((resolve, reject) => {
  //     let options = {
  //       method: 'POST',
  //       url: `${process.env.REACT_APP_API_URL}/residential/basic/plans`,
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         'idToken': idToken, 'platform': 'web'
  //       })
  //     };

  //     return request(options, (error, response, body) => {
  //       let resData = body && JSON.parse(body);
  //       if (error || response.statusCode !== 200) {
  //         let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Datacenter proxies!';
  //         showToastAlert('Error', errorMessage);
  //         resolve(null);
  //       } else {
  //         return resolve(resData);
  //       }
  //     });
  //   });
  // }

  const getProPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/residential/pro/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'idToken': idToken, 'platform': 'web'
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Datacenter proxies!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const getPremiumPlans = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/residential/premium/plans`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'idToken': idToken, 'platform': 'web'
        })
      };

      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching Datacenter proxies!';
          showToastAlert('Error', errorMessage);
          resolve(null);
        } else {
          return resolve(resData);
        }
      });
    });
  }

  const checkPremiumOffer = (idToken) => {
    return new Promise((resolve, reject) => {
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/data/checkPremiumOffer`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'idToken': idToken
        })
      };
      return request(options, (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (resData.offer) {
          return resolve(true);
        }
        return resolve(false);
      });
    });
  }

  const claimOffer = () => {
    if (claiming) { return; }
    setClaiming(true);
    return new Promise(async (resolve, reject) => {
      let idToken = await firebase.auth.currentUser.getIdToken(true);
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/residential/premium/applyPremiumOffer`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'idToken': idToken })
      };
      return request(options, async (error, response, body) => {
        let resData = body && JSON.parse(body);
        if (error || response.statusCode !== 200) {
          let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while claiming offer!';
          showToastAlert('Error', errorMessage);
          setClaiming(false);
          resolve(null);
        } else {
          setEnableOffer(false);
          showToastAlert('Success', 'Offer successfully applied');
          await (new Promise(res => setTimeout(res, 2000)));
          window.location.reload();
          return resolve(resData);
        }
      });
    });
  }

  function applyFill(slider) {
    const percentage = 100 * (slider.value - slider.min) / (slider.max - slider.min);
    const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${settings.background} ${percentage + 0.1}%)`;
    slider.style.background = bg;
  }

  useEffect(() => {
    document.body.classList.add('dash-bg');
    const sliders = document.querySelectorAll('.range-slider');

    Array.prototype.forEach.call(sliders, (slider) => {
      slider.querySelector('.sliderInput').addEventListener('input', (event) => {
        slider.querySelector('.sliderValue').value = event.target.value;
        slider.querySelector('.sliderInput').value = event.target.value;
        applyFill(slider.querySelector('.sliderInput'));
        setNbrOfProxies(event.target.value);
      });
      slider.querySelector('.sliderValue').addEventListener('input', (event) => {
        slider.querySelector('.sliderInput').value = parseInt(event.target.value) || 0;
        applyFill(slider.querySelector('.sliderInput'));
        setNbrOfProxies(parseInt(event.target.value) || 0);
      });
      applyFill(slider.querySelector('.sliderInput'));
    });

    providers.forEach(provider => {
      setUserPlans(plans => ({ ...plans, [provider.key]: {} }));
      setSelectedUserPlans(plans => ({ ...plans, [provider.key]: {} }));
      setUserSubPlanKey(plans => ({ ...plans, [provider.key]: {} }));
      setUserCharts(plans => ({ ...plans, [provider.key]: <DoughnutChart key={Math.random()} text={'0/0 GB'} dataset={[0, 0.01]} /> }));
    })

    props.setOpenedSideMenu('dashboard');

  }, []);

  useEffect(() => {
    Object.keys(selectedUserPlans).forEach(provider => {
      const planDetail = selectedUserPlans[provider];
      if (planDetail.planData) {
        let currentPlan = planDetail.planData;
        let used = (parseFloat(currentPlan.dataLimitMegaBytes / 1000) - parseFloat(currentPlan.dataUsage / 1000000000));
        used = used <= 0 ? 0.0 : used;
        let createDate = new Date(currentPlan.createDate);
        let remainHrs = parseInt(((createDate.setHours(createDate.getHours() + parseInt(currentPlan.validityHours))) - new Date().getTime()) / (1000 * 60 * 60)) || 0;
        let days = parseInt(remainHrs / 24);
        let daysText = '';
        let text = used.toFixed(1) + "/" + parseFloat(currentPlan.dataLimitMegaBytes / 1000).toFixed(1) + ' GB';
        let dataset = [used, parseFloat(currentPlan.dataUsage / 1000000000)];
        if (days > 0) {
          daysText = `${parseInt(days)} days`;
        }
        else if (days <= 0 && remainHrs > 0) {
          daysText = `${parseInt(remainHrs)} hours`;
        }
        else {
          daysText = '0 days';
          text = '0/0 GB';
          dataset = [0, 0.01];
        }
        setUserCharts(plans => ({ ...plans, [provider]: <DoughnutChart key={Math.random()} text={text} dataset={dataset} days={daysText} /> }));
      }
      else {
        setUserCharts(plans => ({ ...plans, [provider]: <DoughnutChart key={Math.random()} text={'0/0 GB'} dataset={[0, 0.01]} /> }));
      }
      if (activeTabKey === provider && planDetail) {
        setCurrentPlan(planDetail);
      }
    })
  }, [selectedUserPlans])

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        try {
          if (!user) {
            history.push('/', { 'from': 'residential' });
            return;
          }

          let idToken = await firebase.auth.currentUser.getIdToken(true);
          const userDiscordId = (await firebase.db.ref(`users/${user.uid}/discordId`).once('value')).val();
          if (userDiscordId && userDiscordId !== '') {
            setHasDiscordId(true);
          }

          firebase.db.ref('plans/residential').on('value', async (snapshot) => {
            const resiData = snapshot.val();
            let categories = resiData['categories'];
            categories = { ...categories };
            setCategories(categories);

            let resiPlansD = { ...resiData };
            delete resiPlansD['categories'];
            delete resiPlansD['active'];

            let allSubPlans = { 'premium': [], 'pro': [] };

            Object.keys(resiPlansD).forEach(planId => {
              let plan = resiPlansD[planId];
              if (!plan.hide && !plan.onlybot && plan.provider !== 'basic') {
                Object.keys(plan.subPlans).forEach(subplanId => {
                  let subPlan = plan.subPlans[subplanId];
                  if (!subPlan.hide) {
                    const dict = { ...subPlan, subplanId: subplanId, planId: planId, provider: plan.provider, bulk: plan.bulk || false, daily: plan.daily || false }
                    allSubPlans[plan.provider].push(dict);
                  }
                })
              }
            })

            setResiPlans(resiPlansD);
            setSortedResiSubPlans(allSubPlans);

            let [proPlans, premiumPlans, enableOffer] = await Promise.all([getProPlans(idToken), getPremiumPlans(idToken), checkPremiumOffer(idToken)]);

            setEnableOffer(enableOffer);

            configurePlanData(proPlans);
            configurePlanData(premiumPlans);

            providers.forEach(provider => {
              let subPlanKey = $(`#${provider.key}_select option:first`).val();
              subPlanKey = subPlanKey ? JSON.parse(subPlanKey) : {};
              setUserSubPlanKey(plans => ({ ...plans, [provider.key]: subPlanKey }));
            })
          });

        } catch (error) {
          console.log(error);
          showToastAlert('Error', error.message);
        }
      });
    })();
  }, [history, firebase.auth]);

  return (
    <div id="page-content-wrapper" className="extra-p">

      <Navbar toggleSideMenu={props.toggleSideMenu} />

      <div className="dash-inner">

        {checkoutModals.map(checkoutModal => (
          checkoutModal
        ))}

        <div className="row">

          {
            enableOffer &&
            <div className="col-12 py-3 px-4 offerDiv mb-3">
              <div>You are eligible for free 2gb premium plan. Claim your data by clicking on Claim Offer</div>
              <div className="form-cta-btn d-inline-block" style={{ flexShrink: '0' }} onClick={claimOffer}>
                <a href="#" className="px-4 bg-danger">Claim Offer</a>
              </div>
            </div>
          }

          <div className="col-lg-6">

            <div className="dash-head">
              <img src={require("assets/images/proxy-gen.svg")} alt="" /> <h4 className="mb-0">Proxy Generation</h4>
            </div>

            <div className="proxy-plans dash-nav">
              <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                {providers.map((provider) => {
                  return (
                    <li className="nav-item" role="presentation" key={provider.key}>
                      <button key={provider.key} className={`nav-link ${activeTabKey === provider.key ? 'active' : ''}`} onClick={changeActiveTab} data-key={provider.key} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{provider.name}</button>
                    </li>
                  )
                })
                }
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div>
                    <div className="dash-form-part">
                      <div className="form-field">
                        <div className="row mb-3">
                          <div className="col">
                            <p className="title">Auth Token</p>
                            <select id="inputState" className="form-select" onChange={changeAuthToken}>
                              {currentPlansData && currentPlansData.data && Object.keys(currentPlansData.data).map(key => {
                                const planD = currentPlansData.data[key];
                                const isBulk = `${planD.bulk}` === 'true';
                                const isDaily = `${planD.daily}` === 'true';
                                let appendType = '';
                                if (isBulk && isDaily) appendType = ' (Daily Bulk)';
                                if (isBulk && !isDaily) appendType = ' (Bulk)';
                                if (!isBulk && isDaily) appendType = ' (Daily)';
                                return (
                                  <option value={key}>{key}{appendType}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div className="col">
                            <p className="title">Continent</p>
                            <select id="continent" className="form-select" onChange={changeContinent}>
                              {continents && Object.keys(continents).map(continent => {
                                return (
                                  <option value={continent}>{continent}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div className="col">
                            <p className="title">Pool</p>
                            <select id="countrySelect" className="form-select" onChange={changeCountry}>
                              {countries && countries.map(obj => {
                                return (
                                  <option value={JSON.stringify(obj)}>{obj.name}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div className="col">
                            <p className="title">Proxy Type</p>
                            <select id="inputState" className="form-select" onChange={changeProxyType}>
                              <option value="sticky">Sticky</option>
                              <option value="dynamic">Dynamic</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="range-part">
                      <p className="range-t">Select package size</p>

                      <div className="range-slider">
                        <input className="sliderInput range-slider__range w-100" type="range" min="0" max="5000" value={numberOfProxies} />

                        <div className="range-details mt-2">
                          <div>0</div>
                          <div>1000</div>
                          <div>2000</div>
                          <div>3000</div>
                          <div>4000</div>
                          <div>5000</div>
                        </div>

                        <div className="number-proxy">
                          <div>No. of proxies</div> <div className="number-proxy-box"> <input className="sliderValue range-slider__value" type="number" onChange={e => setNbrOfProxies.bind(this, e.target.value)} /></div>
                        </div>

                        <div className="form-cta-btn d-inline-block mt-4" onClick={generateProxies}>
                          <a href="#" className="mb-3 px-4">Generate Proxies</a>
                        </div>

                        <div className="d-inline-block mb-2 inner-isp-box mt-4 swap" id="swap" style={{ float: "right" }} onClick={swapProxies}>
                          <img src={require("assets/images/swap.svg")} alt="" />
                        </div>

                        <div className="proxy-details-part mt-4">
                          <textarea style={{ width: '100%', height: '100%', border: 0 }} rows="9" value={generatedProxies}>
                          </textarea>
                        </div>

                        <div className="proxy-btn w-100 d-flex justify-content-between align-items-center mt-4">
                          <a href="#" className="cta-white  d-inline-block" onClick={copyProxies}>
                            <img src={require("assets/images/copy-btn.svg")} className="pe-2" alt="" />
                            Copy All
                          </a>

                          <a href="#" className="cta-white shadow-none bg-transparent ms-auto" onClick={downloadProxies}>
                            <img src={require("assets/images/export-btn.svg")} className="pe-2" alt="" />
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="dash-head">
              <img src={require("assets/images/purchase-head.svg")} alt="" /> <h4 className="mb-0">Purchase</h4>
            </div>

            <div className="purchase-storage">

              {providers.map(provider => {
                return (
                  <div className="plan-wrapper">
                    <img src={require("assets/images/plan-bg-part.svg")} alt="" className="plan-bg" />
                    <div className="plan-svg" style={{ margin: '20px' }}>
                      {userCharts && userCharts[provider.key]}
                    </div>
                    <div className="plan-details">
                      <h4 className="mt-2">{provider.name}</h4>
                      {
                        sortedResiSubPlans[provider.key] &&
                        <>
                          <div className="form-cta-btn align-items-center form-field">
                            <select id={provider.key + '_select'} className="form-select" onChange={changeSubPlan} data-provider={provider.key}>
                              {
                                sortedResiSubPlans[provider.key].map(subplan => {
                                  const isBulk = subplan.bulk ? true : false;
                                  const isDaily = subplan.daily ? true : false;
                                  let appendType = '';
                                  if (isBulk && isDaily) appendType = '(Daily Bulk)';
                                  if (isBulk && !isDaily) appendType = '(Bulk)';
                                  if (!isBulk && isDaily) appendType = '(Daily)';
                                  return (
                                    <option value={JSON.stringify({ subPlanKey: subplan.subplanId, planKey: subplan.planId })}>{subplan.dataLimitMB / 1000} GB - ${subplan.price} - {categories[subplan.category].name} {appendType}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                          <div className="form-cta-btn align-items-center mt-4">
                            <a href="#" className="mb-3 px-5" onClick={e => newPlan(userSubPlanKey[provider.key], selectedUserPlans[provider.key])}>New Plan</a>

                            {selectedUserPlans[provider.key] && selectedUserPlans[provider.key].updatePlanRefID &&
                              <a href="#" className="mb-3 px-5" onClick={e => renewPlan(userSubPlanKey[provider.key], selectedUserPlans[provider.key])} >Topup</a>
                            }
                          </div>
                        </>
                      }
                    </div>
                  </div>
                )
              })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;