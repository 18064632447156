import React from "react";
import { Doughnut, Chart } from 'react-chartjs-2';

class DoughnutChart extends React.Component {
  constructor(props) {
    super(props);
    let text = props.text || '100%';
    let type = props.text || 'resi';
    let days = props.days || '';
    let dataset = props.dataset || [0, 0];

    let data = canvas => {
      const ctx = canvas.getContext('2d');
      var gradient1 = ctx.createLinearGradient(0, 0, 0, 300);
      gradient1.addColorStop(0, 'rgba(99, 131,223, 1)');
      gradient1.addColorStop(0.5, 'rgba(130, 243, 200, 1)');
      gradient1.addColorStop(1, 'rgba(102, 98, 229, 1)');

      var gradient2 = ctx.createLinearGradient(0, 0, 0, 300);
      gradient2.addColorStop(0, 'rgba(99, 131,223, 0.2)');
      gradient2.addColorStop(0.5, 'rgba(130, 243, 200, 0.2)');
      gradient2.addColorStop(1, 'rgba(102, 98, 229, 0.2)');

      return {
        config: [{
          cutoutPercentage: 0
        }],
        text: text,
        days: days,
        labels: [
          'Remaining',
          'Used'
        ],
        datasets: [
          {
            data: dataset,
            backgroundColor: [
              '#ebf2fe',
              '#ebf2fe'
            ],
            weight: 1,
            borderWidth: 0
          },
          {
            data: dataset,
            backgroundColor: [
              gradient1,
              '#ebf2fe'
            ],
            borderWidth: 0
          },
          {
            data: [1],
            backgroundColor: "#FFFFFF",
            weight: 0.03,
            borderWidth: 0
          },
          {
            data: dataset,
            backgroundColor: [
              gradient2,
              '#fcfcfe'
            ],
            weight: 0.6,
            borderWidth: 0
          }
        ]
      };
    }

    let donutOptions = {
      responsive: true,
      cutoutPercentage: 65,
      legend: {
        display: false
      }
    };

    this.state = {
      data,
      donutOptions,
      type: type
    };
  }

  render() {
    const { data, donutOptions, type } = this.state;

    var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function () {
        originalDoughnutDraw.apply(this, arguments);

        var chart = this.chart.chart;
        var ctx = chart.ctx;
        var width = chart.width;
        var height = chart.height;
        const remainDays = chart.config.data.days;

        ctx.font = "500 1.45rem Lato";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#359bec";

        var text = chart.config.data.text,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY - (remainDays !== '' ? 10 : 5));

        ctx.font = "400 0.85rem Lato";
        ctx.fillStyle = "#103a8d";

        if (type !== 'dc' && type !== 'datacenter' && type !== 'isp' && remainDays !== '') {
          textX = Math.round((width - ctx.measureText(remainDays).width) / 2);
          ctx.fillText(remainDays, textX, textY + 17);
        }

        textX = Math.round((width - ctx.measureText(type === 'dc' || type === 'datacenter' || type === 'isp' ? "Remaining" : "Remaining").width) / 2);
        ctx.fillText(type === 'dc' || type === 'datacenter' || type === 'isp' ? "Remaining" : "Remaining", textX, textY + (remainDays !== '' ? 35 : 20));
      }
    });

    return (
      <Doughnut
        options={donutOptions}
        data={data}
        width={170}
        height={170}
      />
    );
  }
}

export default DoughnutChart;
